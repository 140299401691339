<template>
	<div>
		<p v-if="formDataError" class="error">{{ $t('errors.provideCorrectData') }}</p>
		<div class="manipulate-recipient">
			<div>
				<button
					class="btn custom-button shadow-md ml-2 mr-2 text-popup manipulate-recipient"
					v-if="!onlyOneEmail"
					@click="addRecipient"
				>
					+
				</button>
				<button
					class="btn custom-button shadow-md mr-2 text-popup manipulate-recipient"
					@click="removeRecipient"
					v-if="showRemoveRecipient"
				>
					-
				</button>
			</div>
		</div>

		<div
			v-show="displayAddAttachment"
			@mouseenter="displayTooltip('attachMail',true)"
			@mouseleave="displayTooltip('attachMail',false)"
		>
			<span class="font-size-12 mr-10 ml-2">{{ $t('general.resendWithAttachment') }}</span>
			<input
				type="checkbox"
				class="customCheckbox"
				id="addAttachCheck"
				v-model="addAttachment"
				:checked="checkAttachCheckbox"
				:disabled="disableAttachmentsCheckbox"
			>
			<div v-if="displayCombinedSizeTooltip" class="tooltip-container">
				<div
					class="tooltip combined-tooltip"
					:class="{'display-tooltip' : displayCombinedSizeTooltip}"
				>
					{{ combinedToolTip }}
				</div>
			</div>
		</div>
		<div
			class="email-container"
			v-for="(item, index) in formDataItems" :key="index"
		>
			<resend-document-data
				:docsData=docsData
				:selected-le="selectedLe"
				:reportSettings=report
				:email="this.email"
				v-model="formDataItems[index]"
				@emailClicked=test
			/>
		</div>

		<div class="btn-content" v-if="!reportSettings">
			<div>
				<button class="btn custom-button shadow-md mr-2 text-popup" @click="cancel">
					{{ $t('buttons.cancel') }}
				</button>
				<button class="btn custom-button shadow-md mr-2 text-popup"
						@click="resend"
						:disabled="disableButton"
				>
					{{ $t('buttons.resend') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import ResendDocumentData from "../resend/ResendDocumentData.vue"
import i18n from "../../../i18n"

export default {
	name: "ResendDocumentMail",
	components: {ResendDocumentData},
	emits: ['cancel', 'resend'],
	props: {
		reportConfigMails: {
			type: Object,
			required: false,
		},
		reportSettings: {
			type: Boolean,
			required: false,
			default: false
		},
		docId: {
			type: Array
		},
		docsData: {
			type: Array
		},
		legalEntityId: {
			type: String
		},
		mailType: {
			type: String
		},
		onlyOneEmail: {
			type: Boolean,
			required: false,
			default: false
		},
		selectedLe: String,
		email: {
			type: String,
			required: false
		}
	},
	watch: {
		docsData() {
			return this.docsData
		}
	},
	data() {
		return {
			disableButton: false,
			reportEmails: this.reportConfigMails,
			report: this.reportSettings,
			formDataItems: [{}],
			formDataError: false,
			addAttachment: true,
			displayCombinedSizeTooltip: false,
		}
	},
	mounted() {
		if (this.$store.state.documentFileSizes.combinedSize > 10) this.addAttachment = false
	},
	computed: {
		combinedToolTip() {
			if (this.$store.state.documentFileSizes.combinedSize >= 10) {
				return i18n.t('buttons.disableAtttachMailCombinedSizeTooltip')
			}
			return null
		},
		disableAttachmentsCheckbox() {
			return this.$store.state.documentFileSizes.combinedSize >= 10;
		},
		checkAttachCheckbox() {
			return this.$store.state.documentFileSizes.combinedSize < 10;
		},
		displayAddAttachment() {
			if (this.mailType !== "ATTACHMENT") return false

			let merged = false
			let attachments = false
			let addedAttachments = false
			this.docsData.forEach(doc => {
				if (doc.documentData.channels.attachEmail?.merge === true) merged = true
				if (doc.documentData.fileStructure?.attachmentsFileNames?.length > 0) attachments = true
				if (doc.documentData.fileStructure?.attachmentsAdded === true) {
					addedAttachments = true
				}
			})

			if (!attachments) return false
			else if (!merged) return true
			else return addedAttachments;
		},
		showRemoveRecipient() {
			return this.formDataItems.length !== 1;
		}
	},
	methods: {
		displayDisable(type) {
			return type === "attachMail" && this.$store.state.documentFileSizes.combinedSize >= 10;
		},
		displayTooltip(type, display) {
			if (type === "attachMail" && this.displayDisable(type)) {
				this.displayCombinedSizeTooltip = display
			}
		},
		test() {
			this.formDataError = false
		},
		addRecipient() {
			this.formDataItems.push({})
		},
		removeRecipient() {
			this.formDataItems.pop()
		},
		cancel() {
			this.disableButton = false;
			this.$store.commit('displayResendDocument', false)
			this.$emit('cancel')
		},
		validateEmail(email) {
			const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
			return re.test(email)
		},
		async handleErrors() {
			let reason = `Automatically handled, resent via ${this.mailType.toLowerCase()} mail to: `
			this.formDataItems.forEach(item => reason += item.email.trim() + ', ')

			await this.$store.dispatch("documents/handleErrors", {
				formData: {
					docIds: this.docId,
					reason: reason.substring(0, reason.length -2)
				},
				routeName: this.$route.name
			})
		},
		async resend() {
			this.disableButton = true
			let formValidated = true
			this.formDataItems.forEach((item) => {
				if (!this.validateEmail(item.email)) {
					formValidated = false
				}
				if (item.language === undefined || item.language === '') {
					formValidated = false
				}
			})
			if (!formValidated) {
				this.disableButton = false
				this.formDataError = true
				return
			}

			let formData = {
				emailInfo: []
			}

			if (this.displayAddAttachment === false) this.addAttachment = false
			this.docsData.forEach((doc) => {
				if (formValidated) {
					if (this.docsData.length === 1) formData.docId = doc.docId

					let formDataOneDoc = {}
					formDataOneDoc.recipients = this.formDataItems
					formDataOneDoc.mailType = this.mailType
					if (this.docsData.length > 1) formDataOneDoc.docId = doc.docId

					if (this.mailType === "ATTACHMENT" && this.$store.state.documentDetailData.fileStructure) {
						if (this.$store.state.documentDetailData.fileStructure.attachmentsFileNames.length) {
							formDataOneDoc.sendAttachments = this.addAttachment
						} else if (!this.$store.state.resendAfterAddAttachment) {
							formDataOneDoc.sendAttachments = false
						} else {
							formDataOneDoc.sendAttachments = this.addAttachment
						}
					}
					formData.emailInfo.push(formDataOneDoc)
				}
			})
			if (this.docsData[0].errorsAtDocument && !this.docsData[0].errorsHandled) {
				await this.handleErrors()
			}

			if (this.docsData.length === 1) {
				formData.emailInfo = formData.emailInfo[0]
			}
			this.$store.dispatch('documents/resendEmail', formData)

			this.$emit('resend')

			setTimeout(() => {
				this.$store.commit('displayResendDocument', false)
			}, 500)
		}
	}
}
</script>

<style scoped>
.display-tooltip {
	display: block;
}

.combined-tooltip {
	top: -5px;
	padding: 0 5px;
}

.tooltip-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 24px;
	grid-auto-flow: row;
	justify-items: center;
	padding-right: 500px;
}

.btn-content {
	margin-top: var(--generalPaddings);
}

.manipulate-recipient {
	padding-top: 0;
	padding-bottom: 0;
}

.font-size-12 {
	font-size: 14px;
}

.mr-10 {
	margin-right: 10px;
}

</style>
<template>
	<the-spinner v-if="loading"/>

	<div class="flex flex-col" :style="loading ? {display: 'none'} : ''">
		<div v-if="!loading" class="flex flex-row w-full justify-between"
			 style="height: 50px; background-color: #323639; color: white">
			<p class="font-bold ml-10 " style="align-content: center; font-size: 15px">{{ docName }}</p>
			<div class="flex flex-row align-items-center">
				<button @click="downloadPdf">
					<span class="material-icons-outlined m-2">file_download</span>
				</button>
				<button @click="prindPdf">
					<span class="material-icons m-2">print</span>
				</button>
			</div>
		</div>
		<div class="pdf-container" :style="loading ? {display: 'none'} : ''" id="pdfCanvas">
		</div>
	</div>
</template>

<script>
import {ref, onMounted, computed} from 'vue';
import * as pdfjsLib from 'pdfjs-dist';
import store from "@/store";
import 'pdfjs-dist/build/pdf.worker.min.mjs'
import LoadingPage from "@/components/UI/LoadingPage.vue";
import TheSpinner from "@/components/UI/TheSpinner.vue";
import {useRoute} from "vue-router";

export default {
	name: 'PdfViewer',
	components: {TheSpinner, LoadingPage},
	props: {
		src: {
			type: String,
			required: true
		},
		docId: String,
		docName: String,
		accessCode: String,
	},
	setup(props) {
		const route = useRoute()
		const loading = ref(true)
		onMounted(async () => {
			loading.value = true
			let url = `${store.state.domainName}/api/documents/${props.docId}/pdf`
			if (route.name === 'public') {
				url = `${store.state.domainName}/api/documents/public/${props.docId}/pdf?access_code=${props.accessCode}`
			}
			const loadingTask = pdfjsLib.getDocument({
				url: url,
				httpHeaders: {
					Authorization: "bearer" + store.state.token,
				}
			});
			const pdf = await loadingTask.promise;

			let pdfContainer = document.getElementById('pdfCanvas')

			for (let i = 1; i <= pdf.numPages; i++) {
				let pdfCanvas = document.createElement("canvas")
				const context = pdfCanvas.getContext('2d');
				pdfCanvas.style.width = '70%'
				pdfCanvas.style.height = 'auto'
				pdfCanvas.style.marginLeft = '15%'
				pdfCanvas.style.marginBottom = '10px'
				const page = await pdf.getPage(i)
				const viewport = page.getViewport({scale: 2});
				pdfCanvas.height = viewport.height;
				pdfCanvas.width = viewport.width;
				const renderContext = {
					canvasContext: context,
					viewport: viewport
				};
				await page.render(renderContext);
				pdfContainer.append(pdfCanvas)
			}
			loading.value = false
		});

		const downloadPdf = () => {
			store.dispatch("documents/downloadPdf", {
				ids: props.docId,
				docName: props.docName,
				route: route.name,
				accessCode: props.accessCode
			})
		}

		const prindPdf = () => {
			const iframe = document.createElement('iframe');
			iframe.style.position = 'absolute';
			iframe.style.width = '0';
			iframe.style.height = '0';
			iframe.style.border = 'none';
			document.body.appendChild(iframe);

			const doc = iframe.contentWindow.document;
			doc.open();
			// Remove the default added header and footer
			doc.write(`<html>
      						<head>
        						<title>Print PDF</title>
        						<style>@page { margin: 0; }</style>
      						</head>
      						<body></body>
    					</html>
			`);
			doc.close();

			const body = doc.body;
			const canvases = document.getElementById('pdfCanvas').querySelectorAll('canvas');
			canvases.forEach(canvas => {
				const clone = canvas.cloneNode();
				clone.getContext('2d').drawImage(canvas, 0, 0);
				clone.style.width = '100%'
				clone.style.height = 'auto'
				clone.style.marginLeft = '0'
				clone.style.marginBottom = '0'
				body.appendChild(clone);
			});

			iframe.contentWindow.focus();
			iframe.contentWindow.print();

			document.body.removeChild(iframe);
		}

		return {
			loading,
			downloadPdf,
			prindPdf
		}
	}
};
</script>

<style scoped>
.pdf-container {

	padding-bottom: 10px;
	overflow-y: auto;
	max-height: 90vh;
	display: block;
	justify-content: center;
	background-color: #525659;
}

@media screen and (max-width: 950px) {
	.pdf-container {
		max-height: 70vh;
	}
}
</style>
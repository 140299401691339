<template>
	<div class="form-container">
		<div class="form-item ml-2">
			<input
				id='email'
				type='email'
				@input="changeFormData"
				@click="clicked"
				@blur="clearErrors"
				class="form-control text-popup"
				:placeholder="$t('placeholders.email')">
			<p v-if="errors.emptyEmail" class="error">
				{{ $t('errors.correctEmail') }}
			</p>
		</div>
		<div class="form-item ml-2">
			<v-select
				@click="clicked"
				:options="languages"
				v-model="language"
				:clearable=false
				@blur="clearErrors"
			>
			</v-select>
			<p v-if="errors.emptyLang" class="error">{{ $t('errors.choseLang') }}</p>
		</div>
	</div>

</template>

<script>
import i18n from "../../../i18n"

export default {
	name: "ResendDocumentData",
	props: {
		reportConfig: {
			type: Boolean,
			required: false,
		},
		docsData: {
			type: Array,
			required: true
		},
		reportSettings: {
			type: Boolean,
			required: false,
			default: false
		},

		resendBatch: {
			type: Boolean,
			default: false,
		},
		selectedLe: String,
		email: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			i18n: i18n,
			reportConfigData: this.reportConfig,
			language: {
				label: i18n.t('documentDetail.resend.resendSelectLang'),
				value: ''
			},
			formDataItem: {
				email: '',
				language: '',
			},
			errors: {
				emptyEmail: false,
				emptyLang: false,
			},
			currentResendBatch: this.resendBatch
		}
	},
	mounted() {
		if (this.$store.state.documentDetailData?.generalDocumentData?.language){
			this.formDataItem.language = this.$store.state.documentDetailData.generalDocumentData.language
			this.language = this.$store.state.documentDetailData.generalDocumentData.language
		}
		else if (this.$store.state.documentDetailData?.salaryData?.language){
			this.formDataItem.language = this.$store.state.documentDetailData.salaryData.language
			this.language = this.$store.state.documentDetailData.salaryData.language
		}
		else if (this.$store.state.documentDetailData?.billingData?.language){
			this.formDataItem.language = this.$store.state.documentDetailData.billingData.language
			this.language = this.$store.state.documentDetailData.billingData.language
		}

		if (this.email) {
			document.getElementById('email').value = this.email
			this.formDataItem.email = this.email
		}

		this.$emit('update:modelValue', this.formDataItem)
	},
	watch: {
		locale() {
			if (this.language.value === '') {
				this.language.label = i18n.t('documentDetail.resend.resendSelectLang');
			}
		},
		lang(newVal) {
			if (newVal.value !== undefined){
				this.formDataItem.language = newVal.value;
				this.$emit('update:modelValue', this.formDataItem)
			}
		},
	},
	computed: {
		locale() {
			return this.$store.state.locale
		},
		lang() {
			return this.language
		},
		SG() {
			return this.$store.state.supplierGroup.data
		},
		languages() {
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities
			let languages = []

			if (this.docsData.length > 0) {
				this.docsData.forEach(data => {
					if (data.legalEntityId !== undefined && legalEntities[data.legalEntityId]?.templates?.mail?.emailLanguages && legalEntities[data.legalEntityId]?.templates?.mail?.emailLanguages.length > 0) {
						languages.push.apply(languages, legalEntities[data.legalEntityId].templates.mail.emailLanguages)
					}
				})
			}

			if (languages.length <= 0) {
				if (this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages && this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages.length > 0) {
					languages = this.$store.state.supplierGroup.data.templates.mail.emailLanguages
				}
				else languages = this.$store.state.supplierGroup.data.languages
			}

			// filter duplicates
			languages = languages.filter((element, index) => {
				return languages.indexOf(element) === index;
			})

			languages.sort()
			let langs = [];
			languages.forEach(lang => {
				let tempItem = {
					label: lang,
					value: lang,
				}
				langs.push(tempItem)
			})

			return langs
		},
	},
	methods: {
		clearErrors() {
			this.errors.emptyEmail = false
			this.errors.emptyLang = false
		},
		clicked() {
			this.$emit('emailClicked')
		},
		validateEmail(email) {
			const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
			return re.test(email);
		},

		changeFormData(e) {
			this.formDataItem[e.target.id] = e.target.value;
			this.$emit('update:modelValue', this.formDataItem)
		}
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: calc(50% - 10px) calc(50% - 0px);
	grid-template-rows: 50% 50%;
	grid-auto-flow: row;
	border-bottom: 1px solid var(--theme-primary-50);
	padding-bottom: 0;
	grid-column-gap: 0;
}

.form-item {
	padding: calc(0.5 * var(--generalPaddings)) 0;
}

.form-item input {
	margin-right: 10px;
}

.form-item select {
	margin-left: 10px;
}

select {
	color: var(--theme-primary-100);
}

@media only screen and (max-width: 600px) {
	.form-container {
		grid-template-columns: 100%;
		grid-template-rows: 30% 30%;
	}

	.form-item:first-child {
		padding-bottom: 0;
	}
}
</style>
<template>
	<div>
		<pop-up-window
			:show="show && !popupRelease.showRelease"
			:showClose=false
			class="doc-statuses-popup"
			additional-info=""
			:top0="true"
			fixed>
			<template #title>
				<h1 class="text-2xl">
					{{ $t('documentDetail.addAttachmentHeader') }}
				</h1>
			</template>
			<template #content>
				<div class="formData">
					<div>
						<h3>{{ $t('documentDetail.addAttachmentDescription') }}</h3>
					</div>
					<div>
						<input
							type="file"
							multiple="multiple"
							ref="file"
							class="form-control text-popup"
							:accept="acceptedFileTypes"
							@change="uploadFile"
							@click="setError(false)"
						>
					</div>
				</div>
				<div class="mt-2">
					<p class="error" v-if="errorFile">{{ $t('errors.attachFileIsEmpty') }}</p>
					<p class="error" v-if="errorFileType">{{ $t('errors.attachFileWrongType') }}</p>
				</div>
				<div class="btn-content mt-4 grey-top-border">
					<div>
						<button class="btn custom-button shadow-md mr-2 text-popup" @click="close">
							{{ $t('buttons.cancel') }}
						</button>
						<button class="btn custom-button shadow-md mr-2 text-popup" @click="add">
							{{ $t('buttons.add') }}
						</button>
					</div>
				</div>

				<!--Resend after add attachment-->
				<confirmation-with-props
					:description="$t('documentDetail.resendDocumentDescription')"
					:title="'documentDetail.resendDocumentHeader'"
					:show-dialog="popupResend.showResend"
					@cancel-action="close"
					@approve-action="resendDocument"
				></confirmation-with-props>
			</template>
		</pop-up-window>
	</div>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue"
import ConfirmationWithProps from "../../UI/ConfirmationWithProps.vue"
import i18n from "../../../i18n"
import AlertNew from "../../UI/AlertNew.vue"

export default {
	name: "AddAttachment",
	components: {AlertNew, ConfirmationWithProps, PopUpWindow},
	emits: ['releasedDocument', 'showResend', 'close'],
	props: {
		show: Boolean
	},
	data() {
		return {
			files: null,
			errorFile: false,
			errorFileType: false,
			popupRelease: {
				showRelease: false,
				title: 'popUp.release.title',
				descriptionConfirmRelease: 'popUp.release.description',
				showConfirmationRelease: false
			},
			popupResend: {
				showResend: false,
				title: 'documentDetail.resendDocumentHeader',
				descriptionConfirmResend: i18n.t('documentDetail.resendDocumentDescription'),
				showConfirmationResend: false
			},
			document: this.$store.state.documentDetailData
		}
	},
	computed: {
		acceptedFileTypes() {
			if (this.document.held
				&& (this.document.channels.attachEmail?.merge === true
					|| this.document.channels.linkEmail?.merge === true
					|| this.document.edi?.merge === true)) {
				return 'application/pdf'
			}
			return ''
		},
		warningRelease() {
			if (this.$store.state.documentDetailData.held
				&& this.$store.state.documentDetailData.fileStructure.attachmentsAdded
				&& (this.$store.state.documentDetailData.channels.attachEmail?.merge === true
					|| this.$store.state.documentDetailData.channels.linkEmail?.merge === true
					|| this.$store.state.documentDetailData.edi?.merge === true)) {
				return '\n' + i18n.t('confirmation.description.confirmReleaseDocumentMergeAttachments')
			} else return null
		}
	},
	methods: {
		uploadFile() {
			this.files = this.$refs.file.files
			this.errorFileType = false
		},
		setError(value) {
			this.errorFile = value
		},
		async add() {
			if (!this.files) {
				this.setError(true)
				return
			}
			if (this.document.held
				&& (this.document.channels.attachEmail?.merge === true
					|| this.document.channels.linkEmail?.merge === true
					|| this.document.edi?.merge === true)) {
				Array.from(this.files).forEach(file => {
					if (file.type !== 'application/pdf') {
						this.errorFileType = true
					}
				})
			}
			if (this.errorFileType === true) return

			const formData = new FormData()
			for (let i = 0; i <= this.files.length; i++) {
				formData.append('file', this.files[i])
			}
			try {
				await this.$store.dispatch('documents/addAttachment', {
					filesToSend: formData,
					docId: this.$store.state.documentDetailData.docId
				})

				if (this.$store.getters.getDocumentDetailData.held === true) {
					this.$emit("releasedDocument")
					this.close()
				} else {
					this.popupResend.showResend = true
				}
			} catch (err) {
				console.error(err)
				this.$store.state.errorNotification.display = true
			}
		},
		async resendDocument() {
			this.$store.state.documentDetail.displayResendDocument = true
			this.popupResend.showResend = false
			this.$store.state.displayAddAttachment = false
			this.$emit('showResend')
			this.$emit('close')
		},
		close() {
			this.files = null
			this.$store.state.displayAddAttachment = false
			this.popupRelease.showRelease = false
			this.popupResend.showResend = false
			this.errorFileType = false
			this.$emit('close')
			this.setError(false)
		}
	}
}
</script>

<style scoped>
.formData {
	display: grid;
	grid-template-columns: 30% 70%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	align-items: center;
	column-gap: 6px;
}
</style>
<template>
	<div class="container-menu">
		<div @click="clickedTab"
			class="pointer menu-mobile-line"
			v-if="showAtSecuredArea && acceptedTermsAndConditions && displayOverview"
		>
			<router-link :to=routerData.overview.link >{{ $t('overview.title') }}</router-link>
		</div>
		<div @click="clickedTab" class="pointer menu-mobile-line"
			 v-if="showAtSecuredArea && acceptedTermsAndConditions && showUbl"
		>
			<router-link :to=routerData.ubl.link>{{ $t('ubl.title') }}</router-link>
		</div>
		<div @click="clickedTab"
			class="pointer menu-mobile-line"
			v-if="showAtSecuredArea && showPostDrop && acceptedTermsAndConditions"
		>
			<router-link :to=routerData.postDrop.link>{{ $t('postDrop.title') }}</router-link>
		</div>
		<div @click="clickedTab"
			class="pointer menu-mobile-line"
			v-if="showAtSecuredArea && showPayments && acceptedTermsAndConditions"
		>
			<router-link :to=routerData.payments.link>{{ $t('payments.title') }}</router-link>
		</div>
		<div @click="clickedTab"
			class="pointer menu-mobile-line"
			v-if="showAtSecuredArea && showManagement && acceptedTermsAndConditions"
		>
			<router-link :to=routerData.management.link>{{
					$t('management.title')
				}}
			</router-link>
		</div>

		<div @click="clickedTab" class="pointer menu-mobile-line"
			 v-if="showAtSecuredArea && showReports && acceptedTermsAndConditions">
			<router-link :to=routerData.reports.link>{{ $t('reports.title') }}</router-link>
		</div>
		<div @click="clickedTab"
			:class="{styleOnLoginPage : styleOnLoginPage}"
			class="pointer menu-mobile-line" v-if="displayExternalLink"
		>
			<a :href="externalLink.link" target="_blank">
				{{ externalLink.text }}
			</a>
		</div>
		<div @click="clickedTab"
			:class="{styleOnLoginPage : styleOnLoginPage}"
			class="pointer menu-mobile-line"
			v-if="showHelpPage && userHasEmail && !SamlLogin"
		>
			<router-link :to=routerData.help.link>{{ $t('help.title') }}</router-link>
		</div>
		<div @click="clickedTab" v-if="showIngestion" class="menu-mobile-line">
			<router-link :to=routerData.ingestion.link>PDF reading</router-link>
		</div>
		<div
			class="pointer login-media menu-mobile-line"
			v-if="!showAtSecuredArea && showAtPublicLink"
			@click="redirectToLogin"
		>
			<div class="loginButton">{{ $t('buttons.login') }}</div>
		</div>
	</div>
</template>
<script>
import routerData from "@/routerData"
import userGroups from "@/userGoups"

const newLoginPage = window.newLoginPage
export default {
	name: 'TheNavigation',
	emits: ['closeMenu'],
	data() {
		return {
			smallScreen: window.innerWidth < 850,
			externalLink: {
				text: '',
				link: ''
			},
		}
	},
	computed: {
		routerData() {
			return routerData
		},
		showAtSecuredArea() {
			return this.$store.state.userIsAuthenticated
		},
		acceptedTermsAndConditions() {
			return !(userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)
				&& this.$store.state.docType === "BILLING"
				&& !this.$store.state.currentUserData.data.termsAndConditionsAcceptedOn)
		},
		displayOverview() {
			// if billing access is denied from the admin settings than we don't show the overview tab
			if (!this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.additionalPortalConfig?.billing) return false

			// if you are a receiver and billing access is allowed than you have accass to see your documents
			if (this.$store.state.currentUserData.data.group === "ReceiverUser" || this.$store.state.currentUserData.data.group === "ReceiverAdmin") return true

			// if your is accessType is NOT supplier or Optipost (op) than you don't have access to the overview tab
			if (!(userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group) || userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))) return false

			// if your role is supplierSubUser then you only have access to this tab when you have legal entities
			if ((this.$store.state.currentUserData.data.group === "SupplierSubUser" && this.$store.state.currentUserData.data.legalEntities.length === 0)) return false

			// if you get to this point you have access to the overview tab
			return true
		},
		showUbl() {
			// if peppol access is denied from the admin settings than we don't show the ubl tab
			if (!this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.peppol) return false

			// if your is accessType is NOT supplier or Optipost (op) than you don't have access to the ubl tab
			if (!(userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group) || userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group))) return false

			// if your role is supplierSubUser then you only have access to this tab when you have legal entities
			if (this.$store.state.currentUserData.data.group === "SupplierSubUser" && this.$store.state.currentUserData.data.legalEntities.length === 0) return false

			// if you get to this point you have access to the ubl tab
			return true
		},
		styleOnLoginPage() {
			return this.$route.name === "login"

		},
		showIngestion() {
			return this.$store.state.currentUserData.data.email === "sven@nymus.be"
		},
		displayExternalLink() {
			if (this.$store.state.supplierGroup.data?.channelsConfig?.docImage?.configPortal?.externalPortalLinks
				&& Object.keys(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks).length !== 0) {
				if (this.$store.getters.getLocale.toUpperCase()) {
					this.changeExternalLink()
				}
				return true
			}
			return false
		},
		showReports() {
			return (this.$store.state.currentUserData.data.group === userGroups.SupplierGroupSuperAdmin.name
				|| this.$store.state.currentUserData.data.group === userGroups.SupplierSubAdmin.name
				|| this.$store.state.currentUserData.data.group === userGroups.OpHelpdesk.name
				|| this.$store.state.currentUserData.data.group === userGroups.OpAdmin.name
			)
		},
		showPayments() {
			return (this.$store.state.currentUserData.data.group === userGroups.OpHelpdesk.name
					|| this.$store.state.currentUserData.data.group === userGroups.OpAdmin.name
					|| this.$store.state.currentUserData.data.group === userGroups.SupplierSubAdmin.name
					|| this.$store.state.currentUserData.data.group === userGroups.SupplierGroupSuperAdmin.name)
				&& (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.showMandates)
		},
		showPostDrop() {
			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.generalPost
				&& (userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group) || userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group))
			) {
				return this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			}
			return false
		},
		showManagement() {
			return userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| (this.$store.state.currentUserData.data.group === 'SupplierGroupSuperAdmin')
				|| (this.$store.state.currentUserData.data.group === 'SupplierSubAdmin')
				|| (this.$store.state.currentUserData.data.group === 'ReceiverAdmin')
		},
		showHelpPage() {
			if (this.$store.state.userIsAuthenticated && this.$store.state.docType !== "SALARY") {
				return true
			}
			return this.$route.name === "login"
		},
		userHasEmail() {
			return this.$store.state.currentUserData.data.email !== null && this.$store.state.currentUserData.data.email !== ''
		},
		SamlLogin() {
			return (localStorage.getItem("saml") === "true" && this.$store.state.currentUserData.data.group === "ReceiverUser")
		},
		showAtPublicLink() {
			let routeName = this.checkRootName()
			if (this.$store.state.supplierGroup.data.channelsConfig) {
				if (routeName === 'public' && !(this.$store.state.supplierGroup.data.channelsConfig.docImage.allowLogin)) {
					return false
				}
			}
			return !(routeName === 'onboarding'
				|| routeName === 'saml'
				|| routeName === 'login'
				|| routeName === 'channelChoice'
				|| routeName === 'channelChoiceAC'
				|| routeName === 'onboardingAC'
				|| routeName === 'createMandateOk'
				|| routeName === 'mandateOk'
				|| routeName === 'cancelMandateSuccessful'
				|| routeName === 'cancelMandateFail'
				|| routeName === 'onboardingSuccessful'
				|| routeName === 'onboardingUnsuccessful'
				|| routeName === 'cancelMandateCreate'
				|| routeName === 'proxyLogin')
		},
	},
	methods: {
		redirectToLogin() {
			let href = ''
			if (this.$store.state.localHostUrl) {
				let url = window.location.href
				let host = window.location.hostname
				let protocol = window.location.protocol
				let url1 = url.replace(protocol, '').replace(window.location.hostname, '')
				let port = url1.split('/')[2]
				href = protocol + '//' + host + port + '/'
			}
			if (this.$store.state.publicDocumentId === '') {                                                                      //check if it is public link and redirect to home page
				if (this.$store.state.localHostUrl) {                                                                           //check if it is localhost the redirect to localhost
					document.location.href = href
				} else {
					document.location.href = this.$store.state.domainName + '/'                                                 //check if is not NOT localhost and redirect to domainName/
				}
			} else {                                                                                                                 //check if it is NOT public link and redirect to /overview/:docId page if it is not public link
				if (this.$store.state.localHostUrl) {
					document.location.href = href + 'overview/' + this.$store.state.publicDocumentId                //check if it is  localhost the redirect to localhost/overview/:docId
				} else {
					document.location.href = this.$store.state.domainName + '/overview/' + this.$store.state.publicDocumentId       //check if is not NOT localhost and redirect to domainName//overview/:docId
				}
			}
			if (newLoginPage) {
				this.$router.push('/login')
			}
			this.clickedTab()
		},
		clickedTab(){
			if (this.smallScreen) this.$emit('closeMenu')
		},
		changeExternalLink() {
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks && this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks[this.$store.getters.getLocale.toUpperCase()]) {
				this.externalLink.text = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks[this.$store.getters.getLocale.toUpperCase()].text
				this.externalLink.link = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks[this.$store.getters.getLocale.toUpperCase()].link

			} else {
				if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks
					&& Object.keys(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks).length !== 0) {
					this.externalLink.text = Object.values(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks)[0].text
					this.externalLink.link = Object.values(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks)[0].link
				}
			}
		},
		checkRootName() {
			if (this.$route.name === undefined) {
				setTimeout(() => {
					this.checkRootName()
				}, 50)
				return
			}
			return this.$route.name
		},
	}
}
</script>

<style scoped>

.container-menu {
	display: flex;
	flex-direction: row;
	justify-content: end;
}

@media screen and (max-width: 1270px) {
	.menu-mobile-line a, .loginButton {
		padding: calc(0.3 * var(--generalPaddings)) calc(1.5 * var(--generalPaddings));
	}
}
@media screen and (max-width: 1000px){
	.menu-mobile-line a, .loginButton {
		padding: calc(0.2 * var(--generalPaddings)) calc(0.3 * var(--generalPaddings));
	}
}

@media screen and (min-width: 1270px) {
	.menu-mobile-line a, .loginButton {
		padding: calc(0.5 * var(--generalPaddings)) calc(2.5 * var(--generalPaddings));
	}
}
@media screen and (min-width: 850px) {
	.container-menu {
		margin-top: 0;
		margin-bottom: 7px;
		padding: 0;
		display: flex;
		justify-content: end;
	}

	.menu-mobile-line {
		list-style: none;
	}

	.menu-mobile-line a, .loginButton {
		color: var(--theme-primary-500);

		text-decoration: none;
		border: 2px solid var(--theme-primary-10);
		border-radius: 6px;
	}

	.menu-mobile-line a:hover, .loginButton:hover {
		background-color: var(--theme-primary-10);
	}

	.menu-mobile-line a {
		white-space: nowrap;
	}
}

@media screen and (max-width: 850px) {
	.menu-mobile-line {
		text-align: center;
		width: 85%;
		padding: 20px 0;
		border-bottom: solid 1px #b6b8ba;
	}
	.container-menu{
		margin-top: 0;
		margin-bottom: 5px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

</style>
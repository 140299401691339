<template>
    <div class="table-container">
        <div class="button-container">
            <div @click="goToPostDrop" class="go-back">
				<p id="goBack" ></p>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div
                  id="tabulator"
                  ref="tableRef"
                  class="mt-4 table-report table-report--tabulator"
            />
        </div>
    </div>
</template>

<script>
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import en from "../../locales/en.json"
import nl from "../../locales/nl.json"
import fr from "../../locales/fr.json"
import de from "../../locales/de.json"
import sk from "../../locales/sk.json"
import es from "../../locales/es.json"
import {computed, onMounted, ref, watch} from 'vue'
import cash from "cash-dom"
import router from "../../router"
import {useStore} from 'vuex'
import i18n from "../../i18n"
export default {
    name: "ProcessedDocumentTable",
    props: {
        tableData: Array,
    },
    setup(props) {
        const store = useStore()
        const tableRef = ref()
        const tabulator = ref()
        const tableData = ref(props.tableData)
		const tableIsLoading = ref(false)

        let tableColumns = computed(() => {
            let columns = [
                {
                    title: 'DEPARTMENT NAME',
                    field: 'departmentName',
                    cssClass: "text-centered",
                    headerSort: false,
					minWidth: 220,
                    formatter(cell) {
                        const a = cash(
                              `<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     		<div>${cell.getData().department}</div>
                                		</div>`)
                        return a[0]
                    }
                },
                {
                    title: 'POSTDROP NAME',
                    field: 'postDropName',
                    cssClass: "text-centered",
                    headerSort: false,
					minWidth: 220,
                    formatter(cell) {
                        const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().postDropName}</div>
                                	 </div>`)
                        return a[0]
                    }
                },
                {
                    title: 'FILE NAME',
                    field: 'fileName',
                    cssClass: "text-centered",
                    headerSort: false,
                    minWidth: 400,
                    formatter(cell) {
                        const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().fileName}</div>
                                	</div>`)
                        return a[0]
                    }
                },
                {
                    title: 'BATCH ID',
                    field: 'batchId',
                    cssClass: "text-centered",
                    headerSort: false,
                    minWidth: 100,
                    formatter(cell) {
                        const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().batchId}</div>
                                	</div>`)
                        return a[0]
                    }
                },
                {
                    title: 'CREATED AT',
                    field: 'createdAt',
                    cssClass: "text-centered",
                    headerSort: false,
					minWidth: 220,
                    formatter(cell) {
                        const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().createdOn}</div>
                                	</div>`)
                        return a[0]
                    }
                },
                {
                    title: 'PRINTER STATUSES',
                    field: 'printerStatuses',
                    cssClass: "text-centered color-grey",
                    headerSort: false,
					minWidth: 180,
                    formatter(cell) {
                        const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                      	<div>${cell.getData().statusBar}</div>
                                	</div>`)
                        return a[0]
                    }
                }
            ]
            return columns
        })

        watch(() => store.getters.getLocale, function () {
            tabulator.value.setLocale(store.getters.getLocale);
        })

        const goToPostDrop = () => {
            router.push('/postDrop')
        }
        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                data: tableData.value,
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                locale: true,
                headerSort: false,
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
                langs: {
                    "en": en,
                    "nl": nl,
                    "fr": fr,
                    "de": de,
                    "sk": sk,
                    "es": es
                },
                columns: tableColumns.value,
            })
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', ()=> {
				tableIsLoading.value = true
			})
        }
		watch(tableIsLoading, () => {
			if(tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
        onMounted(() => {
            initTabulator()
        })

        return {
            tableRef,
            tabulator,
            goToPostDrop,
        }
    }
}
</script>

<style scoped>
#goBack {
	cursor: pointer;
	font-size: 20px;
}

#goBack:after {
	content: ' \003C \003C';
}

.button-container{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-auto-flow: row;
}
</style>
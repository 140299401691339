<template>
	<div>
		<error-notification/>
		<!-- BEGIN: HTML Table Data -->
		<div class="email-suppressions">
			<div>
				<div>
					<div class="quick-search-container">
						<div class="flex flex-row">
							<input
								type='text'
								v-model="searchTableValue"
								:placeholder="$t('placeholders.search')"
								class="form-control mr-1.5"
								@click=setMailError(false)
								@keyup="sendSearch($event)"
							/>
							<button
								style="flex-basis: 15%"
								class="btn custom-button w-full shadow-md mr-1.5"
								@click="search"
							>
								{{ $t('buttons.search') }}
							</button>
						</div>
						<div class="flex flex-row add-margin-small-screen">
							<extended-search-filter
								current-page="inactiveEmails"
								@search="search"
								@reset-filter="resetSearch"
								:margin-left-small-screen="8"
								class="mr-1.5"
							/>
							<button
								class="btn custom-button w-full shadow-md "
								style="flex-basis: 15%"
								@click="resetSearch"
							>
								{{ $t('buttons.reset') }}
							</button>
						</div>
					</div>
					<div>
						<p class="error" v-if="errors.email">{{ $t('errors.correctEmail') }}</p>
					</div>
				</div>
				<div class="flex mt-5 sm:mt-0"></div>
			</div>
			<filter-label @remove-label="search" style="margin-top: 8px !important;" current-page="inactiveEmails"/>
			<div class="totalNumberDocuments">{{ $t('table.totalNumberInactiveEmails') }}: {{ totalDocuments }}</div>

			<div class="overflow-x-auto scrollbar-hidden test">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				></div>
			</div>
		</div>
		<!-- END: HTML Table Data -->
	</div>

</template>

<script>
import {computed, onMounted, reactive, ref, watch} from 'vue'

import {TabulatorFull as Tabulator} from 'tabulator-tables'
import {useStore} from 'vuex'

import ErrorNotification from "../../UI/ErrorNotification"
import cash from "cash-dom"
import en from "../../../locales/en.json"
import nl from "../../../locales/nl.json"
import de from "../../../locales/de.json"
import fr from "../../../locales/fr.json"
import sk from "../../../locales/sk.json"
import es from "../../../locales/es.json"
import i18n from "../../../i18n"
import moment from 'moment'
import ExtendedSearchFilter from "../../extendedSearch/ExtendedSearchFilter.vue"
import FilterLabel from "../../extendedSearch/FilterLabel.vue"

export default {
	name: "MailsTable",
	components: {FilterLabel, ExtendedSearchFilter, ErrorNotification},
	props: {
		token: String,
		supplierGroupId: String,
		requestUrl: String,
		modelValue: String,
	},
	setup() {
		const store = useStore()

		const format = (date) => {
			const day = date.getDate()
			const month = date.getMonth() + 1
			const year = date.getFullYear()
			return `${day}/${month}/${year}`
		}
		const tableRef = ref()
		const tabulator = ref()
		const pageSize = ref(10)
		const tableIsLoading = ref(false)
		const filter = reactive({
			value: searchTableValue
		});
		const smallScreen = ref(window.innerWidth < 980)

		let tableData = ref(store.state.emailSuppression.data)
		let showExtendedSearch = ref(false)
		let docTypeError = ref(false)
		let documentType = ref('')
		let tableItems = ref(0)
		let errors = ref({
			email: false,
		})
		let searchTableValue = ref(store.state.emailSuppression.search)

		let totalDocuments = computed(() => {
			return store.state.emailSuppression.data.length
		})
		let tableColumns = computed(() => {
			return [
				{
					title: 'EMAIL',
					field: 'email',
					cssClass: "text-centered",
					headerSort: true,
					formatter(cell) {
						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
                                     <div  id = ${cell.getData().mandateId}>${cell.getData().email}</div>
                                </div>`
						)
						return a[0]
					}
				},
				{
					title: 'REASON',
					field: 'reason',
					cssClass: "text-centered",
					headerSort: true,
					formatter(cell) {
						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
                                     <div>${cell.getData().reasonToDisplay}</div>
                                </div>`
						)
						return a[0]
					}
				},
				{
					title: 'CREATEDAT',
					field: 'createdAt',
					headerSort: true,
					formatter(cell) {
						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
                                     <div>${cell.getData().createdAt}</div>
                                </div>`
						)
						return a[0]
					}
				},
				{
					title: 'REACTIVATE',
					field: 'reactivate',
					cssClass: "text-centered",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().reason === "HardBounce") {
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-loop2  icon-color "></i>
                                                  <span class="tooltip-inner reactivate-email">${i18n.t('tooltips.reactivateEmail')}</span>
                                            </div>
                                    </div> `);
							cash(a).on('click', async function () {
								await store.dispatch('users/reactivateEmail', {
									email: cell.getData().email,
									column: "reactivateEmail"
								})
							})
							return a[0]
						}
						if (cell.getData().reason === "SpamComplaint" || cell.getData().reason === "ManualSuppression") {
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-loop2  icon-color icon-color-disable"></i>
                                                  <span class="tooltip-inner spam-complaint">${i18n.t('tooltips.spamComplaint')}</span>
                                            </div>
                                    </div> `)
							return a[0]
						}
					}
				}
			]
		})

		watch(() => store.state.emailSuppression.emailReactivated, async (newVal) => {
			if (newVal) {
				resetSearch()
				store.commit("emailReactivated", false)
			}
		})
		watch(() => store.state.emailSuppression.data, (newVal) => {
			if (store.state.emailSuppression.isReady) {
				tableData.value = newVal
				initTabulator()
			}
		})

		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value)

			let params = {
				EmailAddress: filter.value,
			}
			if (store.state.tableSorters[0].order) {
				params.sortOn = store.state.tableSorters[0]
			}
			if (filter.value === '') {
				delete params.quick
			}
			tabulator.value = new Tabulator(tableRef.value, {
				data: tableData.value,
				headerSortElement: "<span><i claas='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				paginationMode: 'local',
				paginationSize: pageSize.value,
				paginationInitialPage: store.state.tableInitialPage.mandates,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: tableColumns.value,
			})
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const setMailError = (payload) => {
			errors.value.email = payload
		}
		const sendSearch = (event) => {
			if (event.key === "Enter") search()
		}
		const search = () => {
			if (searchTableValue.value !== "" && searchTableValue.value) {
				const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
				let validEmail = re.test(searchTableValue.value);

				if (!validEmail) {
					setMailError(true)
					return
				}
			}
			let email = searchTableValue.value
			if (searchTableValue.value === '') {
				email = null
			}
			let fromDate = null
			let toDate = null

			if (store.state.extendedSearchValue.extendedSearch.period) {
				if (store.state.extendedSearchValue.extendedSearch.period.from !== null) {
					if (typeof store.state.extendedSearchValue.extendedSearch.period.from === 'object') fromDate = moment(store.state.extendedSearchValue.extendedSearch.period.from.toISOString()).format('YYYY-MM-DD');
					if (typeof store.state.extendedSearchValue.extendedSearch.period.from === 'string') fromDate = store.state.extendedSearchValue.extendedSearch.period.from
				}
				if (store.state.extendedSearchValue.extendedSearch.period.to !== null) {
					if (typeof store.state.extendedSearchValue.extendedSearch.period.to === 'object') toDate = moment(store.state.extendedSearchValue.extendedSearch.period.to.toISOString()).format('YYYY-MM-DD');
					if (typeof store.state.extendedSearchValue.extendedSearch.period.to === 'string') toDate = store.state.extendedSearchValue.extendedSearch.period.to
				}
			}

			store.dispatch('retrieveSuppressionEmails', {
				formData: {
					email: email,
					from: fromDate,
					to: toDate,
					reason: !store.state.extendedSearchValue.extendedSearch.reason?.value ? null : store.state.extendedSearchValue.extendedSearch.reason.value
				}
			})
		}
		const setExtendedSearch = () => {
			if (!showExtendedSearch.value) {
				resetSearch()
			}
		}
		const resetSearch = () => {
			showExtendedSearch.value = false
			searchTableValue.value = ''
			store.state.extendedSearchValue.extendedSearch.reason = {
				label: 'dropdowns.mailReason.all',
				value: null
			};

			if (store.state.extendedSearchValue.extendedSearch.period) {
				store.state.extendedSearchValue.extendedSearch.period.from = null;
				store.state.extendedSearchValue.extendedSearch.period.to = null;
			}

			let email = searchTableValue.value
			if (searchTableValue.value === '') {
				email = null;
			}

			store.dispatch('retrieveSuppressionEmails', {
				formData: {
					email: email,
					from: null,
					to: null,
					reason: ''
				}
			});
		}
		onMounted(() => {
			initTabulator()
		})
		return {
			tableRef,
			filter,
			totalDocuments,
			tabulator,
			docTypeError,
			documentType,
			smallScreen,
			search,
			sendSearch,
			resetSearch,
			tableItems,
			searchTableValue,
			showExtendedSearch,
			setExtendedSearch,
			format,
			errors,
			setMailError,
		}
	}
}
</script>

<style scoped>
.quick-search-container {
	display: flex;
	flex-direction: row;
	width: 400px;
	height: 36px;
}

.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
}

.leg-entity-container .table-report img, .table-image {
	box-shadow: none !important;
}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	padding-top: var(--generalPaddings);
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.extended-search-check-box span {
	margin-right: 10px;
	position: relative;
	top: -5px;
}

.date-container h2 {
	justify-self: start;
	align-self: center;
}

@media only screen and (max-width: 739px) {
	.quick-search-container {
		display: flex;
		flex-direction: column;
		width: 400px;
		height: 37px
	}

	.add-margin-small-screen {
		margin-top: 5px
	}
}

@media only screen and (max-width: 1030px) {
	.quick-search-container {
		display: flex;
		flex-direction: row;
		width: 400px;
		height: 37px
	}
}
</style>
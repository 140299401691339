import {createStore} from 'vuex'
import main from './main'
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const env = window.environment;
import moment from 'moment';
import cash from 'cash-dom';
import i18n from "../i18n";
import documentsModule from './documents/index'
import searchModule from './search/index'
import peppolModule from './peppol/index'
import userModule from './users/index'
import directDebetModule from './mandate/index'
import departmentModule from './department/index'
import reportModule from './reports/index'
import profileModule from './profile/index'
import fileModule from './file/index'

const store = createStore({
	modules: {
		main,
		documents: documentsModule,
		search: searchModule,
		peppol: peppolModule,
		users: userModule,
		directDebet: directDebetModule,
		department: departmentModule,
		report: reportModule,
		profile: profileModule,
		file: fileModule
	},
	state() {
		return {
			domainName: env.bffUrl,
			keycloakUrl: env.keycloakUrl,
			keycloakClientId: env.keycloakClient,

			API: {
				curentSG: '/api/supplier-group/current',
				branding: '/api/branding/theme/',
				search: '/api/documents/search',
				downloadDocumentsZip: '/api/documents/zip',
				currentUser: '/api/users/user/current',
				legalEntitiesData: '/api/supplier-group/legal-entities',
				getDocument: '/api/documents',
				getPublicDocument: '/api/documents/public/',
				readDocument: '/api/documents/read/',
				downloadGuide: '/api/supplier-group/help-instructions/',
				updateUserProfile: '/api/users/user/',
				getUsers: "/api/users/get",
				deleteUser: "/api/users/user/",
				updateUser: "/api/users/user/",
				resetPassword: '/api/users/user/password/reset',
				resetPasswordUser: '/api/users/user/password/reset-portal',
				searchReceiver: '/api/search/receivers',
				receivers: '/api/search/receivers',
				searchAllReceivers: '/api/search/receivers/all',
				inviteUser: '/api/users/invite',
				updateUserLogin: '/api/users/user/',
				acceptTos: '/api/users/user/tos/accept',
				sgTerms: '/api/supplier-group/terms',
				leTerms: '/api/supplier-group/terms',
				getDocZip: '/api/documents/:documentId/zip',
				getDocPdf: '/api/documents/:documentId/pdf',
				getPublicPdf: '/api/documents/public/:documentId/pdf',
				getUbl: '/api/documents/:documentId/attachment/ubl',
				downloadReport: '/api/reporting/:supplierGroupId/reports/',
				resendEmail: '/api/legal-entity/:legalEntityId/documents/:docId/resend/LINK',
				reprintDocument: '/api/documents/:docId/reprint',
				exportUsers: '/api/users/export',
				getFor: "/api/users/get-for",
				updateReportConfigSg: "/api/reporting/:supplierGroupId/email-report/report-settings",
				updateReportConfigLe: "/api/reporting/:supplierGroupId/legal-entities/:legalEntityNumber/report-settings",
				resendEdi: "/api/documents/:documentId/resend-edi",
				resendMultipleEdi: "/api/documents/resend-edi",
				mandates: "/api/payment/mandates",
				mandateOnboarding: "/api/receivers/public/mandate-onboarding",
				mandateSaveChoice: "/api/receivers/public/mandate-onboarding-choice",
				mandateCreate: "/api/payment/public/legal-entity/:entityId/client/:clientId/mandate/create",
				mandateCancelPublic: "/api/payment/public/legal-entity/:entityId/client/:clientId/mandate/:mandateId/cancel",
				mandateCancel: "/api/payment/legal-entity/:entityId/client/:clientId/mandate/:mandateId/cancel",
				flowCompleted: "/api/receivers/public/mandate-onboarding/flow-completed",
				getMandateOnboarding: '/api/receivers/public/mandates/:accessCode',
				getMandateLink: '/api/payment/legal-entity/:entityId/client/:clientId/mandate/:mandateId/get/link',
				updateReceiver: "/api/receivers/onboarding-choice",
				addReceiver: "/api/receivers/onboarding-choice/add-receiver",
				deleteReceiver: "/api/receivers/onboarding-choice",
				failedTransasctions: "/api/payment/transactions",
				channelChoice: "/api/receivers/public/onboarding",
				publicOnboardingChoice: "/api/receivers/public/onboarding-choice",
				downloadMandate: '/api/payment/legal-entity/:legalEntityId/mandate/:mandateId/pdf',
				retrieveSuppressions: '/api/mail/retrieve-suppressions',
				deleteSuppression: '/api/mail/delete-suppression/:email',
				changePassword: '/api/users/user/password/change',
				addAttachment: '/api/documents/:docId/attachment/add',
				uploadFilePostDrop: "/api/postal-service/overlay/:mailType",
				validateUBL: "/api/ubl/validate",
				smpParticipants: "/api/peppol/smp/participants",
				departmentUploadFiles: "/api/postal-service/upload/:departmentCode/:printJob/:postalType/:printMode/:batch",
				checkMandate: "/api/documents/:documentId/mandate/check",
				proxyLogin: "/api/users/exchange-token/:token",
				setPaymentStatusPaid: "/api/documents/confirm-payment",
				setPaymentStatusUnpaid: "/api/documents/unconfirm-payment",
				toggleVisibility: '/api/documents/visibility',
				releaseDocument: '/api/documents/:docId/release',

				departments: '/api/departments',
				createDepartment: '/api/departments/create',
				editDepartment: '/api/departments/:departmentId',
				addDepartmentUser: '/api/departments/:departmentId/users/:userId',
				editMandate: '/api/payment/legal-entity/:entityId/client/:clientId/mandate/:mandateId/update',
				checkOnPeppol: "https://staging-api.nymus.eu/v1/gateway/peppol/lookup/:vatNumber",
				checkOnPeppolPROD: "https://api.nymus.eu/v1/gateway/peppol/lookup/:vatNumber",
				checkOnPeppolChangeId: "https://staging-api.nymus.eu/v1/gateway/peppol/lookup/:id/:documentType",
				checkOnPeppolChangeIdPROD: "https://api.nymus.eu/v1/gateway/peppol/lookup/:id/:documentType",
				postalService: '/api/postal-service/search',
				supplierUsers: '/api/users/supplier-users',
				retrieveBic: "https://openiban.com/validate/:ibanNumber?getBIC=true",
				processedDocumentData: "/api/postal-service/:documentId",
				handleErrors: "/api/documents/errors-handled",
				pendingUploadedFiles: "/api/postal-service/pending",
				startPrint: "/api/postal-service/start",
				searchUbl: "/api/peppol/search",
				ublDetailData: '/api/peppol/:documentId',
				reofferTransaction: "/api/payment/legal-entity/:entityId/transaction/:transactionId/reoffer",
				deleteBatch: "/api/postal-service/:batchId",
				cleanUp: '/api/documents/demo/clean-up',
				handleFailedTransaction: "/api/payment/transactions/handled",
				getParticipantId: '/api/documents/:documentId/participant-id',

				getPreferencesTable: '/api/preferences/table/suppliergroup/:supplierGroupId',
				createPreferencesTable: '/api/preferences/table',
				updatePreferencesTable: '/api/preferences/table/:preferenceId',
				deletePreferencesTable: '/api/preferences/table/:preferenceId',

				keycloakToken: env.keycloakUrl + '/realms/' + env.keycloakClient + '/protocol/openid-connect/token',
				keycloakLogout: env.keycloakUrl + '/realms/' + env.keycloakClient + '/protocol/openid-connect/logout',
				keycloakIdentityProvider: 'https://staging-modde-idp.nymus.eu/auth/realms/modde/protocol/openid-connect/token',
			},

			userIsAuthenticated: false,
			token: '',
			refreshToken: '',
			tokenIsValid: null,
			proxyToken: false,
			resendBatch: true,
			errors: {
				loginCredentials: false
			},
			route: {
				from: '',
				to: ''
			},


			redirectPageAfterLogin: "/overview",
			emailSuppression: {
				data: [],
				isReady: false,
				emailReactivated: false,
				search: null,
				check: {
					attachBounces: [],
					attachSpam: [],
					attachInactive: [],
					linkBounces: [],
					linkSpam: [],
					linkInactive: [],
				}
			},
			tableInitialPage: {
				mandates: 1,
				failedTransactions: 1,
				managementUsers: 1,
				managementReceivers: 1,
				overview: 1,
				postDrop: 1,

			},
			failedTransactionUpdated: false,
			managementActiveTab: "users",
			tableSorters: [{}],
			tablePostdropSorters: [{}],
			tempFile: '',
			tosAccepted: '',
			onboarding: {
				channelChoice: {
					display: false,
					data: {},
					dataSaved: false,
					accessCodeReplyLength: -1,
				},
				accessCodeReplyLength: -1,

				dataDraft: {
					validityEnd: "2022-09-22T15:35:58.000Z",

				},
				data: {},

				successfulTextField: '',
				successfulData: {},
				isReady: false
			},


			supplierGroup: {
				isReady: false,
				data: {},
				branding: {}
			},
			docType: '',
			currentUserData: {
				isReady: false,
				data: {
					lastLogin: ''

				}
			},

			legalEntitiesData: {
				isReady: false,
				data: {}
			},
			errorNotification: {
				display: false,
				type: '',
				errorText: '',
				errorStatus: '',

			},
			extraInfo: {
				display: false
			},
			generalNotification: {
				display: false,
				type: '',
				errorText: '',
				errorStatus: '',
				text: '',
				startIngestion: '',
				cookiesWarningText: '',
				deleteMandateText: '',
				couldNotCancelMandate: '',
				couldNotCreateMandate: '',
				sessionExpiredText: '',
				generalErrorText: '',
				emailReactivated: '',
				emailCorrected: '',
				fileUploaded: '',
				passwordChanged: '',
				editMandateText: '',
				fileUploadedError: '',
				errorsHandled: '',
				startPrint: "",
				departmentExists: "",
				reofferTransaction: "",
				deleteBatch: "",
				reofferTransactionMandateCancelled: "",
				cleanUp: "",
				markHandledFailedTransaction: "",
				documentDetailEmailReactivated: false,
				savedCustomizedTable: false
			},
			resetLoggedInUserPassword: false,
			documentDeleted: false,

			loadingNotification: {
				display: false,
				type: ""
			},

			tableSearchValue: '',
			tableSearchValueMandates: '',
			tableSearchValueReceivers: '',
			tableSearchPage: "1",
			overviewTableDataRefreshed: false,
			overviewPageNumber: 1,
			overviewTableSort: false,

			extendedSearchValue: {
				extendedSearch: {},
				lastPageExtendedSearch: '',
				showExtendedSearch: false
			},

			tableSearchValueManagement: '',
			dataManagementExtendedSearch: [],
			tableSearchPageManagement: "1",
			currentPageManagementSearch: 0,


			publicDocument: false,
			publicDocumentAccessCode: '',


			localHostUrl: false,

			documentDetailData: {},
			displayHandleError: false,
			errorsFixedByUser: false,

			publicDocumentInfo: {
				data: {},
				isReady: 'false'
			},
			publicDocumentId: '',

			documentsInfo: {
				data: [],
				pagination: {
					currentPage: '',
					totalPages: '',
				}
			},
			locale: '',
			documentFileSizes: {
				pdfSize: 0,
				combinedSize: 0
			},
			branding: {},
			documentDetail: {
				displayResendDocument: false
			},

			userDataUpdated: false,
			userInvited: false,
			userManagement: {
				userData: {},
				userReceivers: [],
				displayViewUser: false,
				displayDeleteUser: false,
				displayEditUser: false,
				displayResetPassword: false,
				displayManageUser: false,
				displayInviteUser: false,
				displayFindUser: false,
			},
			receiverDataUpdated: false,
			receiverManagement: {
				receiverData: {},
				displayEditReceiver: false,
				displayDeleteReceiver: false,
				displayAddReceiver: false,
				viewMode: false,
				createReceiverId: ''
			},
			receivers: {
				options: []
			},


			receiversList: {
				data: {},
				isReady: false
			},
			mandates: {
				mandateData: {},
				displayCancelMandate: false,
				displayEditMandate: false,
				displayBankAccount: false
			},
			mandateCanceled: false,


			overviewTable: {
				billing: {
					displayVisibilityColumn: true,
					displayPaymentColumn: true,
					displayPaymentButtonColumn: true,
					displayViewPropertiesColumn: true,
				},
				salary: {
					displayAmountColumn: true,
				}
			},

			overviewTableRefreshed: false,

			tabulator: {
				isClickBlocked: false
			},
			showCustomizedTable: false,
			columnsOverview: [],
			customizedColumns: null,
			extraInfoCustomizedColumns: null,
			reactiveRow: null,

			AllNamesColumnsOverviewBilling: [
				"docId", "viewProperties", "attachmentFiles", "logo", "legalEntityName", "docType", "customerName",
				"clientId", "docNumber", "visibility", "docDate", "dueDate", "payment", "amount", "downloadUBl",
				"language", "vatNrReceiver", "vatNrSender", "totalTaxableAmount", "totalAmountTaxes", "customerPOReference",
				"address", "channels"
			],
			firstRenderOverviewPage: true,
			columnsOverviewBilling: {
				viewProperties: {
					title: '',
					field: 'viewProperties',
					width: '3%',
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing">
                                    ${cell.getData().viewPropertiesColumns}
                              </div>`
					}

				},
				attachmentFiles: {
					title: '',
					field: 'attachmentFiles',
					width: '2%',
					headerSort: false,
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center align-items-center common">
                                                            <div class="paper-clip">
                                                                ${cell.getData().attachmentFileIcon}
                                                            </div>                      
                                                       </div> `);
						return a[0];
					}
				},
				logo: {
					title: '',
					field: 'logo',
					width: '10%',
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing logo ">
                                    <img src="${cell.getData().logo}" class="${cell.getData().logo}">
                                </div>`
					}
				},
				legalEntityName: {
					title: 'LEGAL ENTITY',
					field: 'legalEntityName',
					width: "8%",
					cssClass: 'text-start',
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing">
                                   ${cell.getData().legalEntityName}
                                </div>`
					}
				},
				docType: {
					title: 'DOC TYPE',
					field: 'docType',
					width: '8%',
					cssClass: 'text-centered',
					formatter(cell) {
						return `<div  class="TEST font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell billing-doc-type" 
                                    data-doctype=${cell.getData().docType}  >
                                        ${cell.getData().docType}
                                </div>`
					}
				},
				customerName: {
					title: 'CUSTOMER NAME',
					field: 'customerName',
					hozAlign: "center",
					cssClass: 'text-centered customer-name',
					width: '20%',
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap">
                                    <div class="customer-name-outer">
								        <span class="customer-name-text">${cell.getData().customerName} </span>
								        <span class="tooltip tooltip-billing"> id: ${cell.getData().clientId}</span>
							        </div>
                                </div>`
					}
				},
				docNumber: {
					title: 'DOC NUMBER',
					field: 'docNumber',
					hozAlign: "center",
					cssClass: 'text-centered',
					width: '10%',
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing">
                                        ${cell.getData().docNumber}
                                </div>`
					}
				},
				docDate: {
					title: 'CREATED',
					field: 'docDate',
					hozAlign: "center",
					width: '7%',
					cssClass: 'text-centered',
					formatter(cell) {
						let date = '';
						if (!(cell.getData().docDate === undefined || cell.getData().docDate === '')) {
							date = (moment(cell.getData().docDate).format("DD/MM/YYYY"));
						}

						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell">
                                             ${date}
                                       </div>`
						)
						return a[0];
					}
				},
				visibility: {
					title: 'VISIBILITY',
					field: 'visibility',
					hozAlign: "center",
					cssClass: 'text-centered',
					headerSort: false,
					width: '5%',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                            				<p> ${cell.getData().visibility} </p>
                                    					</div> `)
						return a[0]
					}
				},
				dueDate: {
					title: 'DUE DATE',
					field: 'dueDate',
					width: '7%',
					cssClass: 'text-centered',
					hozAlign: "center",
					formatter(cell) {
						let date = '';
						date = (moment(cell.getData().dueDate).format("DD/MM/YYYY"));

						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center billing tab-cell">
                                            <div>
                                                  ${date}
                                            </div>
                                        </div>`
						)
						return a[0];
					}
				},
				payment: {
					title: 'PAYMENT',
					field: 'payment',
					hozAlign: "center",
					cssClass: 'text-centered',
					width: '8%',
					formatter(cell) {
						let translation = {
							en: require('../locales/en.json'),
							nl: require('../locales/nl.json'),
							fr: require('../locales/fr.json'),
							de: require('../locales/de.json'),
							es: require('../locales/es.json'),
							sk: require('../locales/sk.json')
						}
						let status = cell.getData().paymentStatusTooltip;
						let toolTip = '';
						if (translation[store.state.locale].paymentStatusesTooltips[status] !== undefined && status !== '') {
							toolTip = `<span class="tooltip tooltip-billing payment-status ${status}">${translation[store.state.locale].paymentStatusesTooltips[status]}</span>`
						}

						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                    						<div class="customer-name-outer payment-statuses">
                                            					${cell.getData().payment}
																${toolTip}
                                     						</div>
                                    					</div> `)
						return a[0];
					}
				},
				amount: {
					title: 'AMOUNT',
					field: 'amount',
					width: '8%',
					cssClass: 'text-centered',
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell">
                                    ${cell.getData().amount}
                                </div>`
					}
				},

			},
			extraBillingColumns: {
				clientIdColumn: {
					title: 'CLIENT ID',
					field: 'clientId',
					hozAlign: "center",
					cssClass: 'text-centered customer-name',
					width: '5%',
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap">
								        <span class="customer-name-text">${cell.getData().clientId}</span>
                                </div>`
					}
				},
				downloadUBlColumn: {
					title: 'DOWNLOAD',
					field: 'downloadUBl',
					width: "2%",
					cssClass: "text-centered color-grey",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().showDownloadUBL) {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                        	<i class="icon-arrow-down"></i>
                                            <span class="tooltip-inner download-xml tooltip-ml-min20">${cell.getData().downloadUBLTooltip}</span>
                                        </div>
                                     </div> `)
							cash(a).on('click', function () {
								store.dispatch("documents/downloadUbl", {
									ids: cell.getData().docId,
									number: cell.getData().docNumber
								})
								store.state.tabulator.isClickBlocked = true
							})
							return a[0]
						}
						else {
							const a = cash(`<div></div>`)
							return a[0]
						}
					},
					cellClick: function () {
						store.state.tabulator.isClickBlocked = true
					}
				},
				languageColumn: {
					title: 'LANGUAGE',
					field: 'language',
					width: "3%",
					cssClass: "text-centered customer-name",
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().language}</span>
                                        </div>
                                     </div> `)

						return a[0]
					}
				},
				vatNrReceiverColumn: {
					title: 'Vat Nr Receiver',
					field: 'vatNrReceiver',
					width: "7%",
					cssClass: "text-centered customer-name",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().vatNrReceiver){
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().vatNrReceiver}</span>
                                        </div>
                                     </div> `)

							return a[0]
						} else {
							const a = cash(`<div></div>`)
							return a[0]
						}
					}
				},
				vatNrSenderColumn: {
					title: 'VATNRSENDER',
					field: 'vatNrSender',
					width: "7%",
					cssClass: "text-centered customer-name",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().vatNrSender){
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().vatNrSender}</span>
                                        </div>
                                     </div> `)

							return a[0]
						} else {
							const a = cash(`<div></div>`)
							return a[0]
						}
					}
				},
				totalTaxableAmountColumn: {
					title: 'totalTaxableAmount',
					field: 'totalTaxableAmount',
					width: "8%",
					cssClass: "text-centered customer-name",
					formatter(cell) {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().totalTaxableAmount}</span>
                                        </div>
                                     </div> `)

							return a[0]
					}
				},
				totalAmountTaxesColumn: {
					title: 'totalAmountTaxes',
					field: 'totalAmountTaxes',
					width: "8%",
					cssClass: "text-centered customer-name",
					formatter(cell) {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().totalAmountTaxes}</span>
                                        </div>
                                     </div> `)

							return a[0]
						}
				},
				customerPOReferenceColumn: {
					title: 'customerPOReference',
					field: 'customerPOReference',
					width: "10%",
					cssClass: "text-centered customer-name",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().customerPOReference){
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().customerPOReference}</span>
                                        </div>
                                     </div> `)

							return a[0]
						} else {
							const a = cash(`<div></div>`)
							return a[0]
						}
					}
				},
				addressColumn: {
					title: 'address',
					field: 'address',
					width: "20%",
					cssClass: "text-centered customer-name",
					headerSort: false,
					formatter(cell) {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().fullAddress}</span>
                                        </div>
                                     </div> `)

							return a[0]
					}
				},
				channelsColumn: {
					title: 'channels',
					field: 'channels',
					width: "10%",
					cssClass: "text-centered customer-name",
					headerSort: false,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                             <span class="customer-name-text">${cell.getData().channels}</span>
                                        </div>
                                     </div> `)

						return a[0]
					}
				},
			},
			columnsOverviewSalary: [
				{
					title: '',
					field: 'viewPropertiesColumns',
					width: 60,
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing">
                                    ${cell.getData().viewPropertiesColumns}
                                </div>`
					}
				},
				{
					title: 'LEGAL ENTITY',
					field: 'legalEntityName',
					headerSort: false,
					cssClass: 'text-centered',
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing grid grid-rows-1 justify-items-center">
                                    ${cell.getData().legalEntityName}
                                </div>`
					}
				},
				{
					title: 'DOC TYPE',
					field: 'docType',
					minWidth: 100,
					cssClass: 'text-centered',
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary-doc-type billing tab-cell" data-doctype=${cell.getData().docType}>
                                    ${cell.getData().docType}
                                </div>`
					}
				},
				{
					title: 'PERIOD',
					field: 'period',
					cssClass: 'text-centered',
					minWidth: 120,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center billing general">
                                    ${cell.getData().period}
                                </div>`
					}
				},
				{
					title: 'EMPLOYEE',
					field: 'employee',
					cssClass: 'text-centered',
					minWidth: 140,
					headerSort: true,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
							        <div class="customer-name-outer">
                          		        <span class="customer-name-text">${cell.getData().employee}</span>
                          		        <span class="tooltip tooltip-salary">id: ${cell.getData().clientId}</span>
                                    </div>
                                </div>`
					}
				},
				{
					title: 'DOCUMENT DATE',
					field: 'docDate',
					cssClass: 'text-centered',
					width: 120,
					formatter(cell) {
						let docDate = '';
						if (!(cell.getData().docDate === undefined || cell.getData().docDate == '')) {
							docDate = (moment(cell.getData().docDate).format("DD/MM/YYYY"));
						}

						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center billing general">
                                    ${docDate}
                                </div>`
					}
				},

			],
			columnsOverviewGeneral: [
				{
					title: '',
					field: 'viewPropertiesColumns',
					width: 60,
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing">
                                    ${cell.getData().viewPropertiesColumns}
                                </div>`
					}
				},

				{
					title: 'CUSTOMER NAME',
					field: 'customerName',
					hozAlign: "center",
					cssClass: 'text-centered customer-name',
					minWidth: 140,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
                                    <div class="customer-name-outer">
								        <span class="customer-name-text">${cell.getData().customerName} </span>
								        <span class="tooltip tooltip-billing"> id: ${cell.getData().clientId}</span>
							        </div>
                                </div>`
					}
				},
				{
					title: 'LICENSE PLATE',
					field: 'licensePlate',
					cssClass: 'text-centered',
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center general tab-celln">
                                    <div>
                                        ${cell.getData().licensePlate}
                                    </div>
                                </div>`
					}
				},
				{
					title: 'START DATE',
					field: 'startDate',
					cssClass: 'text-centered',
					hozAlign: "center",
					formatter(cell) {
						let date = '';
						if (!(cell.getData().startDate == undefined || cell.getData().startDate == '')) {
							date = (moment(cell.getData().startDate).format("DD/MM/YYYY"));
						}

						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center general tab-cell">
                                            <div>
                                                ${date}
                                            </div>
                                       </div>`
						)
						return a[0];
					}
				},
				{
					title: 'END DATE',
					field: 'endDate',
					cssClass: 'text-centered',

					formatter(cell) {
						let date = '';
						if (!(cell.getData().endDate == undefined || cell.getData().endDate == '')) {
							date = moment(cell.getData().endDate).format("DD/MM/YYYY");
						}
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center general tab-cell">
                                    <div>
                                        ${date}
                                    </div>
                                </div>`
					}
				},

			],

			columnsDocDetail: [],
			docDetailTable: {
				billing: {
					displayVisibilityColumn: true,
					displayPaymentColumn: true,
					displayPaymentButtonColumn: true,
					displayViewPropertiesColumn: true,
				},
				salary: {
					displayVisibilityColumn: true,
					displayPaymentColumn: true,
					displayViewPropertiesColumn: true,
					displayAmountColumn: true
				},
				general: {
					displayViewPropertiesColumn: true
				}
			},
			columnsDocDetailBilling: [
				{
					title: '',
					field: 'logo',
					width: 100,
					headerSort: false,
					hozAlign: "center",
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing logo">
                                    <img src="${cell.getData().logo}" class="${cell.getData().logo}">
                                </div>`
					}
				},
				{
					title: 'LEGAL ENTITY',
					field: 'legalEntityName',
					width: 250,
					cssClass: 'text-centered',
					hozAlign: "center",
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing">
                                    ${cell.getData().legalEntityName}
                                </div>`
					}
				},
				{
					title: 'DOC TYPE',
					field: 'docType',
					width: 80,
					cssClass: 'text-centered',
					hozAlign: "center",
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell billing-doc-type" 
									  data-doctype=${cell.getData().docType}>
                                        ${cell.getData().docType}
                                </div>`
					}
				},
				{
					title: 'CUSTOMER NAME',
					field: 'customerName',
					hozAlign: "center",
					minWidth: 140,
					cssClass: 'text-centered customer-name',
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general customer-name-outer">
																	 
                                    <span span class="customer-name-text">${cell.getData().customerName}</span>
                                    <span class="tooltip tooltip-clientId tooltip-billing">id: ${cell.getData().clientId}</span>
                                </div>`
					}
				},
				{
					title: 'DOC NUMBER',
					field: 'docNumber',
					cssClass: 'text-centered',
					hozAlign: "center",
					minWidth: 120,
					maxWidth: 180,
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing">
                                    ${cell.getData().docNumber}
                                </div>`
					}
				},
				{
					title: 'DOCUMENT DATE',
					field: 'docDate',
					width: 90,
					cssClass: 'text-centered',
					headerSort: false,
					hozAlign: "center",
					formatter(cell) {
						let date = (moment(cell.getData().docDate).format("DD/MM/YYYY"));

						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell">
                                            ${date}
                                       </div>`
						)
						return a[0];
					}
				},
				{
					title: 'DUE DATE',
					field: 'dueDate',
					width: 90,
					cssClass: 'text-centered',
					headerSort: false,
					hozAlign: "center",
					formatter(cell) {
						let date = '';
						if (!(cell.getData().dueDate === undefined || cell.getData().dueDate === '')) {
							date = (moment(cell.getData().dueDate).format("DD/MM/YYYY"));
						}
						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center billing tab-cell">
                                            <div>
                                                ${date}
                                            </div>
                                        </div>`
						)
						return a[0];
					}
				},
				{
					title: 'AMOUNT',
					field: 'amount',
					width: 140,
					cssClass: 'text-centered',
					hozAlign: "center",
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell">
                                    ${cell.getData().amount}
                                </div>`
					}
				},

			],
			columnsDocDetailSalary: [
				{
					title: 'LEGAL ENTITY',
					field: 'legalEntityName',
					minWidth: 200,
					cssClass: "text-start",
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap salary billing">
                                    ${cell.getData().legalEntityName}
                                </div>`
					}
				},
				{
					title: 'DOC TYPE ',
					field: 'docType',
					width: 120,
					cssClass: "text-centered",
					headerSort: false,
					formatter(cell) {
						return `<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary-doc-type billing tab-cell" 
								     data-doctype=${cell.getData().docType}>
                                        ${cell.getData().docType}
                                </div>`
					}
				},
				{
					title: 'PERIOD',
					field: 'period',
					width: 120,
					cssClass: "text-centered",
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center billing general">
                                    ${cell.getData().period}
                                </div>`
					}
				},
				{
					title: 'EMPLOYEE',
					field: 'employee',
					cssClass: 'text-centered',
					minWidth: 140,
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
							        <div class="customer-name-outer">
                          		        <span class="customer-name-text">${cell.getData().employee}</span>
                          		        <span class="tooltip tooltip-salary">id: ${cell.getData().clientId}</span>
                                    </div>
                                </div>`
					}
				},
				{
					title: 'DATE',
					field: 'docDate',
					width: 100,
					cssClass: "text-centered",
					headerSort: false,
					formatter(cell) {
						let docDate = '';
						if (!(cell.getData().docDate === undefined || cell.getData().docDate === '')) {
							docDate = (moment(cell.getData().docDate).format("DD/MM/YYYY"));
						}

						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center billing general">
                                    ${docDate}
                                </div>`
					}
				},
			],
			columnsDocDetailGeneral: [
				{
					title: 'CUSTOMER NAME',
					field: 'customerName',
					hozAlign: "center",
					cssClass: 'text-centered',
					headerSort: false,
					height: "54",
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
						            <div class="customer-name-outer">
							            <span class="customer-name-text">${cell.getData().customerName}</span>
							            <span class="tooltip tooltip-general">id: ${cell.getData().clientId}</span>
						            </div>
                                </div>`
					}
				},
				{
					title: 'LICENSE PLATE',
					field: 'licensePlate',
					cssClass: "text-centered",
					headerSort: false,
					formatter(cell) {

						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center general tab-celln">
                                    <div>
                                        ${cell.getData().licensePlate}
                                    </div>
                                </div>`
					}
				},
				{
					title: 'START DATE',
					field: 'startDate',
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: false,
					formatter(cell) {
						let date = '';
						if (!(cell.getData().startDate === undefined || cell.getData().startDate === '')) {
							date = (moment(cell.getData().startDate).format("DD/MM/YYYY"));
						}
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center general tab-cell">
                                            <div>
                                                ${date}
                                            </div>
                                        </div>`
						)
						return a[0];
					}
				},
				{
					title: 'END DATE',
					field: 'endDate',
					cssClass: "text-centered",
					headerSort: false,
					formatter(cell) {
						let date = '';
						if (!(cell.getData().endDate === undefined || cell.getData().endDate === '')) {
							date = moment(cell.getData().endDate).format("DD/MM/YYYY");
						}
						return `<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center general tab-cell">
                                    <div>
                                        ${date}
                                    </div>
                                </div>`
					}
				},

			],

			paymentActiveTab: '',
			displayResetOwnPassword: false,
			fromResetPassword: false,
			displayAddAttachment: false,
			displayAskPopUp: false,
			askPopUpAction: '',
			resendAfterAddAttachment: false,
			passwordResetSuccessDisplay: false,
			pageYScrollPosition: 0,
			tableSearchPlaceHolder: "Loading",
			mandateExists: false,
			postDrop: {
				displayValidate: false,
				displayCreateDepartment: false,
				departmentCreated: false,
				departmentData: {},
				displayEditDepartment: false,
				displayDeleteDepartment: false,
				displayAddDepartmentUser: false,
				displayDeleteDepartmentUser: false,
				displayViewDepartmentUser: false,
				displayDepartmentUploadFiles: false,
				usersData: [],
				departmentsSet: false,
				extendedSearch: {
					displayExtendedSearch: false,
					departmentCode: {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''},
					printStatus: {label: i18n.t('dropdowns.printStatuses.allStatuses'), value: ''}
				},
				uploadedFiles: {
					displayExtendedSearch: false,
					departmentCode: {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''},
					batch: {label: i18n.t('dropdowns.postDrop.batch') + "4", value: ''}
				},
				pendingBatches: {
					ids: [],
					isReady: false
				},
				pendingTable: {
					data: [],
					allBatchIds: [],
					isReady: false,
					viewFilesData: [],
					deleteBatchData: '',
					displayViewFiles: false,
					displayDeleteBatch: false

				},
				uploadFilePrintMode: "",
				uploadBatchId: '',
				printBatchAfterUpload: false,
			},
			displayUploadFileLoader: false,
			displayConfirmation: false,

			userDepartmentCodeList: [],

			uploadedBathes: 0,
			peppolIsChecked: false,

			vatCheck: {
				isOnPeppol: false,
				status: ''
			},


			uploadedFileErrorOccurred: false,

			processedDocumentData: {},
			postDropTableSearchValue: '',

			supplierUsersList: [],
			errorUploadedFiles: [],
			uploadedFileNames: [],
			uploadedFileNamesError: [],
			resendErrorUploadedFiles: false,
			displayResendErrorFilesButton: true,
			iban: {
				isChecked: false,
				data: {}
			},
			ibanIsChecked: false,
			documentErrorHandled: false,

			tokenIsChecking: false,

			totalFilesNumber: 0,
			displayLoader: false,
			validationResponse: null,

			ublTableSearch: {
				displayExtendedSearch: false,
				quick: '',
				extendedSearch: {
					documentNumber: '',
					senderId: '',
					ReceiverId: '',
					senderName: '',
					ReceiverName: '',
					peppolMessageType: {label: i18n.t('dropdowns.peppolMessageTypes.AllTypes'), value: ''},
					direction: {label: i18n.t('dropdowns.directions.allDirections'), value: ''},

				}
			},

			ublDocumentData: {},
			viewUblDetail: false,
			validatePopUp: false,
			ublDocumentDataIsReady: false,
			manualExist: false,
			activeComponent: "",

			checkTokenIMax: 0,
			checkTokenICurrent: 0,

			extendedSearch: {
				overview: {
					quickSearch: ''
				}
			},

			regex: {
				emailRegex: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
			}
		}
	},
	getters:
	getters,
	mutations:
	mutations,
	actions:
	actions,
})

export function useStore() {
	return store
}

export default store

<template>
	<div
		v-if="(this.$store.getters.getDocumentDetailData.held === undefined || this.$store.getters.getDocumentDetailData.held === false) && mailData">
		<h2 class="text-base font-bold">
			{{
				this.type === 'attachMail'
					? $t('documentDetail.documentStatusesInfo.documentWithAttachment')
					: $t('documentDetail.documentStatusesInfo.documentWithLink')
			}}
		</h2>

		<div v-for="(item, key) in mailData" :key="key">
			<div v-if="item.find(i => i.description?.includes('Out of Office'))">
				<label-with-icon
					:margin-left="10"
					icon-name="warning_amber"
					:label="$t(this[this.type + 'Titles'].outOfOffice)"
					:icon-severity-color="this.color.outOfOffice"
					:font-size-label="16"
					:font-size-icon="20"
					:center-icon="false"
				/>
			</div>
			<div v-else-if="item.length > 0">
				<label-with-icon
					:margin-left="10"
					icon-name="error_outline"
					:label="$t(this[this.type + 'Titles'][key])"
					:icon-severity-color="this.color[key]"
					:font-size-label="16"
					:center-icon="false"
					:font-size-icon="20"
				/>
			</div>
			<div v-for="(email, index) in item" :key="index">
				<div class="mail-time">
					<label-with-icon
						:margin-left="20"
						icon-name="mail"
						:label="email.email"
					/>
					<div class="extra-margin">
						<label-with-icon
							:margin-left="5"
							icon-name="calendar_month"
							:label="email.time"
							:outlined="false"
						/>
					</div>
				</div>

				<!--Spam specific error-->
				<div
					v-if="key === 'spam' && item.length > 0 && displayOnPublic && displayReactivateEmail"
					class="email-info justify-start"
				>
					{{ $t('general.spamMailIsInPostMark') }}
				</div>
				<div v-else-if="email.errorReason === 'INACTIVE_RECIPIENT' || email.errorReason === 'Hard bounce'"
					 class="ml-11">
					<p class="ml-0.5">
						{{
							translateMailErrorInactiveRecipient(email.errorReason, email.email).includes('emailErrors.inactiveMailIsInPostMarkReasonHardBounce') || translateMailErrorInactiveRecipient(email.errorReason, email.email) === 'documentDetail.documentStatusesInfo.mailErrors.hardBounce'
								? $t(translateMailErrorInactiveRecipient(email.errorReason, email.email)).split(' ').slice(0, -1).join(' ')
								: $t(translateMailErrorInactiveRecipient(email.errorReason, email.email))
						}}
						<span
							v-if="translateMailErrorInactiveRecipient(email.errorReason, email.email).includes('emailErrors.inactiveMailIsInPostMarkReasonHardBounce') || translateMailErrorInactiveRecipient(email.errorReason, email.email) === 'documentDetail.documentStatusesInfo.mailErrors.hardBounce'"
							@click="deleteSuppression(email.email)">
						<button style="text-decoration: underline; color: #215893">
							{{
								$t(translateMailErrorInactiveRecipient(email.errorReason, email.email)).split(' ').pop().replace('.', '')
							}}
						</button>.
					</span>
					</p>
				</div>
				<div v-else class="flex flex-row ml-11">
					<p class="ml-0.5"> {{ translateMailErrors(email.errorReason) }}</p>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="this.$store.getters.getDocumentDetailData.held === true && mailData">
		<hold-information
			:title="$t(type === 'attachMail' ? 'documentDetail.documentStatusesInfo.documentWithAttachment' : 'documentDetail.documentStatusesInfo.documentWithLink')"
			:description=" $t('documentDetail.documentStatusesInfo.documentWithLinkHeld')"
			:information-receiver-text="$t('documentDetail.documentStatusesInfo.documentReceivers')"
			:receivers-data="mailData.sentEmail"
		/>
	</div>
	<confirmation-with-props
		:description="$t('general.resendThisDocument')"
		:show-dialog="showConfirmationResend"
		@approve-action="showResendDocument = true; showConfirmationResend = false"
		@cancel-action="showConfirmationResend = false"
	/>
	<resend-document
		v-if="showResendDocument"
		:docId="[docId]"
		:legalEntityId="legalEntityId"
		:docsData="[{docId: docId, legalEntityId: legalEntityId, documentData: this.$store.state.documentDetailData, ublValid: this.$store.state.documentDetailData.fileStructure.ublFileName}]"
		:show-resend-document="showResendDocument"
		@close-pop-up="showResendDocument = false"
		@close-pop-up-cancel="showResendDocument = false"
		:email="reactivatedEmail"
	/>
</template>

<script>
import LabelWithIcon from "@/components/UI/LabelWithIcon.vue"
import userGroups from "@/userGoups"
import i18n from "@/i18n"
import HoldInformation from "@/components/documentDetail/statuses/HoldInformation.vue"
import ConfirmationWithProps from "@/components/UI/ConfirmationWithProps.vue"
import ResendDocument from "@/components/documentDetail/resend/ResendDocument.vue"

export default {
	components: {ResendDocument, ConfirmationWithProps, HoldInformation, LabelWithIcon},
	props: {
		type: String,
		mailData: Object,
		docId: String,
		legalEntityId: String
	},
	data() {
		return {
			reactivateText: '',
			showConfirmationResend: false,
			showResendDocument: false,
			reactivatedEmail: undefined,
			attachMailTitles: {
				deliveredEmail: 'documentDetail.documentStatusesInfo.documentWithAttachmentDelivered',
				errorEmail: 'documentDetail.documentStatusesInfo.documentWithAttachmentNotDelivered',
				inactiveEmail: 'documentDetail.documentStatusesInfo.documentWithAttachmentInactive',
				sentEmail: 'documentDetail.documentStatusesInfo.documentWithAttachmentSent',
				openEmail: 'documentDetail.documentStatusesInfo.documentWithAttachmentOpened',
				spam: 'documentDetail.documentStatusesInfo.documentWithAttachmentSpam',
				outOfOffice: 'documentDetail.documentStatusesInfo.documentOutOfOfficeWarning'
			},
			linkMailTitles: {
				deliveredEmail: 'documentDetail.documentStatusesInfo.documentWithLinkDelivered',
				errorEmail: 'documentDetail.documentStatusesInfo.documentWithLinkNotDelivered',
				inactiveEmail: 'documentDetail.documentStatusesInfo.documentWithLinkInactive',
				sentEmail: 'documentDetail.documentStatusesInfo.documentWithLinkSent',
				openEmail: 'documentDetail.documentStatusesInfo.documentWithLinkOpened',
				spam: 'documentDetail.documentStatusesInfo.documentWithLinkSpam',
				outOfOffice: 'documentDetail.documentStatusesInfo.documentOutOfOfficeWarning'
			},
			color: {
				deliveredEmail: 'success',
				errorEmail: 'error',
				inactiveEmail: 'error',
				sentEmail: 'info',
				openEmail: 'success',
				spam: 'error',
				outOfOffice: 'warning'
			}
		}
	},
	computed: {
		displayOnPublic() {
			return this.$route.name !== "public"
		},
		displayReactivateEmail() {
			return !!(userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))
		},
	},
	methods: {
		isInPostMark(field, email) {
			let isInPM = false
			this.$store.state.emailSuppression.check[field].forEach(item => {
				if (item.email === email) {
					isInPM = true
				}
			})
			return isInPM
		},
		async deleteSuppression(email) {
			await this.$store.dispatch('users/reactivateEmail', {email: email})
			this.showConfirmationResend = true
			this.$store.state.generalNotification.documentDetailEmailReactivated = false
			this.reactivatedEmail = email
		},
		translateMailErrors(message) {
			if (message === undefined) return ''
			const translationMap = {
				"dns_error": "documentDetail.documentStatusesInfo.mailErrors.DNSError",
				"attach_too_big": "emailErrors.attachmentTooBig",
				"invalid_email": "documentDetail.documentStatusesInfo.mailErrors.invalidEmail",
				"attachments_too_big": "documentDetail.documentStatusesInfo.mailErrors.attachmentsTooBig",
				"email_request_error": "documentDetail.documentStatusesInfo.mailErrors.emailRequestError",
				"soft_bounce": "documentDetail.documentStatusesInfo.mailErrors.softBounce",
				"message_delayed": "documentDetail.documentStatusesInfo.mailErrors.messageDelayed",
				"isp_block": "documentDetail.documentStatusesInfo.mailErrors.ISPBlock",
				"spam_notification": "documentDetail.documentStatusesInfo.mailErrors.spamNotification",
				"auto_responder": "documentDetail.documentStatusesInfo.mailErrors.outOfOffice",
				"other": "documentDetail.documentStatusesInfo.mailErrors.other",
				"dmarc_policy": "documentDetail.documentStatusesInfo.mailErrors.dmarcPolicy",
				"sender_signature_not_found": "documentDetail.documentStatusesInfo.mailErrors.senderSignatureNotFound"
			}
			const sanitizedMessage = message.toLowerCase().replace(' ', "_");
			// Get the message based on the key in the translation map
			let errorMessage = translationMap[sanitizedMessage]

			// if the message cannot be found in the translationmap just return the key
			if (errorMessage === undefined) {
				return message
			} 
			// translate the translationKey and return the value
			return this.$t(errorMessage)
		},
		translateMailErrorInactiveRecipient(message, email) {
			let field = 'attachInactive'
			if (this.type === 'linkMail') field = 'linkInactive'
			if (message.includes('Hard bounce')) {
				if (!this.isInPostMark('attachBounces', email)) {
					return 'documentDetail.documentStatusesInfo.mailErrors.hardBounceAlreadyReactivated'
				} else return 'documentDetail.documentStatusesInfo.mailErrors.hardBounce'
			} else if (this.isInPostMark(field, email)) {
				let reason = ''
				this.$store.state.emailSuppression.check[field].forEach(item => {
					if (item.email === email) {
						reason = item.reason
					}
				})
				if (reason === 'HardBounce') {
					return 'emailErrors.inactiveMailIsInPostMarkReasonHardBounce'
				} else if (reason === 'SpamComplaint') {
					return 'emailErrors.inactiveMailIsInPostMarkReasonSpam'
				}
			} else return 'documentDetail.documentStatusesInfo.mailErrors.hardBounceAlreadyReactivated'

			return ''
		}
	}
}
</script>

<style scoped>

.mail-time {
	display: flex;
	flex-direction: row;
}

.email-info {
	margin-left: 20px;
	justify-self: start;
}

.justify-start {
	justify-self: start;
}


@media screen and (max-width: 1250px) and (min-width: 1000px) {
	.mail-time {
		flex-direction: column;
		align-items: start;
	}
	.extra-margin {
		margin-left: 25px;
	}
}

@media only screen and (max-width: 650px) {
	.mail-time {
		flex-direction: column;
	}

	.extra-margin {
		margin-left: 25px;
	}
}
@media only screen and (max-width: 1000px) and (min-width: 650px) {
	.mail-time {
		flex-direction: row;
		align-items: start;
	}

	.extra-margin {
		margin-left: 0;
	}
}
</style>
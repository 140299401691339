<template>
	<div>
		<!--calendar from -> to-->
		<div>
			<p>{{ $t('extendedSearch.documentDate') }} </p>
		</div>

		<div class="one-line items-center">
			<div class="flex flex-row items-center" :style="showInOneColumn ? {width: '100%'} : {width: '50%'} ">
				<vue-date-picker
					style="padding: 0 !important;"
					:class="showInOneColumn ? '' : 'mr-1.5'"
					class="mb-2.5 w-full"
					v-model="extendedSearch.period.from"
					auto-apply
					format="dd/MM/yyyy"
					:enableTimePicker=false
				>
					<template #input-icon>
						<span class="material-icons calendar-icon">calendar_month</span>
					</template>
					<template #clear-icon="{clear}">
						<span class="material-icons clear-icon" @click="clear">close</span>
					</template>
				</vue-date-picker>
			</div>
			<p v-if="!showInOneColumn" style="font-size: 30px">/</p>
			<div class="flex flex-row items-center" :style="showInOneColumn ? {width: '100%'} : {width: '50%'}">
				<vue-date-picker
					:class="showInOneColumn ? '' : 'ml-1.5'"
					class="mb-2.5 w-full"
					v-model="extendedSearch.period.to"
					auto-apply
					format="dd/MM/yyyy"
					:enableTimePicker=false
				>
					<template #input-icon>
						<span class="material-icons calendar-icon">calendar_month</span>
					</template>
					<template #clear-icon="{clear}">
						<span class="material-icons clear-icon" @click="clear">close</span>
					</template>
				</vue-date-picker>
			</div>
		</div>
		<!--Legal entity & docType-->
		<div class="one-line w-full mb-2.5">
			<vue-select
				v-model="extendedSearch.legalEntityNumber"
				:options="legalEntitiesNames"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
			>
				<template #option="option">
					{{ option.value === null ? $t(option.label) : option.label }}
				</template>
				<template #selected-option="option">
					{{ option.value === null ? $t(option.label) : option.label }}
				</template>
			</vue-select>

			<vue-select
				v-model="extendedSearch.documentType"
				:options="documentTypes"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:class="showInOneColumn ? '' : 'ml-2.5'"
				:clearable="false"
			>
				<template #option="option">
					{{ $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) }}
				</template>
			</vue-select>
		</div>
		<!--client ID & document number-->
		<div class="one-line mb-2.5">
			<input
				class="form-control"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				v-model="extendedSearch.clientId"
				:placeholder="clientIdPlaceholder"
			/>
			<input
				class="form-control"
				:class="showInOneColumn ? '' : 'ml-2.5'"
				v-model="extendedSearch.documentNumber"
				:placeholder="$t('extendedSearch.documentNumber')"
			/>
		</div>
		<!--error channels & paymentStatus-->
		<div class="one-line mb-2.5" :style="showErrorChannelsInRowReverse">
			<vue-select
				v-if="!!this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus"
				v-model="extendedSearch.paymentStatus"
				:options="paymentStatusOptions"
				:class="classesErrorChannels"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
			>
				<template #option="option">
					{{ $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) }}
				</template>
			</vue-select>
			<vue-select
				v-else-if="!this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus && channels.length > 1 && !userIsReceiver"
				v-model="extendedSearch.channel"
				:options="channels"
				:class="classesErrorChannels"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
			>
				<template #option="option">
					{{ $t(option.label) === '' ? option.label : $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) === '' ? option.label : $t(option.label) }}
				</template>
			</vue-select>
			<div
				v-else
				class="ml-2.5"
				style="width: 50%"
			/>

			<vue-select
				v-if="!userIsReceiver"
				v-model="extendedSearch.errorChannels"
				:options="errorChannelsOptions"
				:class="userIsReceiver || !showInOneColumn ? 'mr-2.5' :  ''"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
			>
				<template #option="option">
					{{ $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) }}
				</template>
			</vue-select>

		</div>
		<div class="one-line mb-2.5" v-if="displayIncludeHandled">
			<div v-if="displayIncludeHandled" class="includeHandled" style="width: 50%;">
				<span>{{ $t('extendedSearch.includeHandled') }}</span>
				<input type="checkbox"
					   class="customCheckbox ml-2"
					   :value="extendedSearch.includeHandled"
					   :checked="extendedSearch.includeHandled"
					   @change="setIncludeHandled"
				/>
			</div>
			<div
				class="ml-2.5"
				style="width: 50%"
			/>
		</div>

		<div v-if="!userIsReceiver" class="one-line mb-2.5">
			<vue-select
				v-if="!!this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus && channels.length > 1 && !userIsReceiver"
				v-model="extendedSearch.channel"
				:options="channels"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
			>
				<template #option="option">
					{{ $t(option.label) === '' ? option.label : $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{ $t(option.label) === '' ? option.label : $t(option.label) }}
				</template>
			</vue-select>
			<div
				v-if="this.$store.state.supplierGroup.data.holdRelease && !userIsReceiver"
				:class="showInOneColumn ? '' : 'ml-2.5 w-1/2'"
				style="display: flex; flex-direction: row; align-content: center; flex-wrap: wrap"
			>
				<p style="flex-basis: auto; font-size: 14px">{{ $t('extendedSearch.unreleasedDocuments') }}</p>
				<toggle-slide
					style="margin-left: 10px; flex-basis: auto"
					@update-value="extendedSearch.released = !extendedSearch.released"
					:center-item="false"
					:bool="extendedSearch.released"
					:renew-bool="true"
				/>
			</div>
			<div
				v-else
				class="ml-2.5"
				style="width: 50%"
			/>
		</div>
	</div>
</template>
<script>

import ToggleSlide from "../../UI/ToggleSlider.vue"
import userGroups from "../../../userGoups"
import i18n from "../../../i18n"
import VueDatePicker from "@vuepic/vue-datepicker"
import '@vuepic/vue-datepicker/dist/main.css';
import VueSelect from "vue3-select"

export default {
	components: {ToggleSlide, VueDatePicker, VueSelect},
	data() {
		return {
			showInOneColumn: window.innerWidth < 980,
			extendedSearch: {},
			userIsReceiver: userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)
		}
	},
	created() {
		this.extendedSearch = this.extendedSearchStartValue()
	},
	mounted() {
		// We are doing it like this, so it doesn't update the main class without needing to press search
		for (let filterElement in this.$store.state.extendedSearchValue.extendedSearch) {
			if (this.$store.state.extendedSearchValue.extendedSearch[filterElement] !== undefined) {
				this.extendedSearch[filterElement] = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch[filterElement]))
			}
		}
		window.addEventListener('resize', () => this.showInOneColumn = 'change var')
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler() {
				this.extendedSearch = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch))
				if (!this.extendedSearch.period) {
					this.extendedSearch.period = {
						from: null,
						to: null
					}
				}
				if (!this.extendedSearch.legalEntityNumber) this.extendedSearch.legalEntityNumber = {
					label: 'general.allLegalEntity',
					value: null
				}
				if (!this.extendedSearch.documentType) {
					this.extendedSearch.documentType = {
						label: "general.noDocType",
						value: ''
					}
				}
				if (!this.extendedSearch.clientId) this.extendedSearch.clientId = ''
				if (!this.extendedSearch.documentNumber) this.extendedSearch.documentNumber = ''
				if (!this.extendedSearch.errorChannels) {
					this.extendedSearch.errorChannels = {
						label: "dropdowns.errors.noErrorChannels",
						value: ''
					}
				}
				if (!this.extendedSearch.includeHandled) this.extendedSearch.includeHandled = false
				if (!this.extendedSearch.paymentStatus) {
					this.extendedSearch.paymentStatus = {
						label: 'extendedSearch.overview.paymentStatus.noFilterOnPaymentStatus',
						value: '',
					}
				}
				if (!this.extendedSearch.channel) this.extendedSearch.channel = {
					label: 'dropdowns.channels.noChannel',
					value: ''
				}
			},
			deep: true
		},
		showInOneColumn() {
			this.showInOneColumn = window.innerWidth < 980
		}
	},
	computed: {
		userGroups() {
			return userGroups
		},
		legalEntitiesNames() {
			let leNames = []
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities;

			for (const le in legalEntities) {
				let name = '';
				if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
					name = legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				} else {
					name = legalEntities[le].name
				}

				let tempItem = {
					label: name,
					value: legalEntities[le].entityNumber
				}
				leNames.push(tempItem)
			}

			if (leNames.length > 1) {
				leNames.sort(function (a, b) {
					const textA = a.label.toUpperCase()
					const textB = b.label.toUpperCase()
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
				})
			}


			leNames.unshift({label: 'general.allLegalEntity', value: null})
			return leNames
		},
		documentTypes() {
			let docTypes = []
			let adminDocTypes
			if (this.userIsReceiver) adminDocTypes = "displayableDocTypes"
			else adminDocTypes = "searchableDocTypes"

			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig
				&& this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig[adminDocTypes]) {
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig[adminDocTypes].forEach(type => {
					let translation = type.replace('_', '')
					if (type === "INVOICE") {
						if (this.$store.state.docType === "BILLING") {
							docTypes.push({label: "billing.invoice", value: type})
						}
						if (this.$store.state.docType === "SALARY") {
							docTypes.push({label: "salary.salarySlip", value: type})
						}
					} else {
						docTypes.push({label: "billing." + translation.toLowerCase(), value: type})
					}
				})
			}
			docTypes.sort((a, b) => {
				const textA = a.value.toUpperCase()
				const textB = b.value.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
			docTypes.unshift({
				label: "general.noDocType",
				value: ''
			})
			return docTypes
		},
		clientIdPlaceholder() {
			let clientIdPlaceholder = i18n.t('extendedSearch.customerId')
			if (this.$store.state.supplierGroup.data.sgType === "SALARY") {
				clientIdPlaceholder = i18n.t('extendedSearch.employeeId')
			}
			return clientIdPlaceholder
		},
		errorChannelsOptions() {
			let channelsOptions = [
				{label: "dropdowns.errors.noErrorChannels", value: ''},
				{label: "dropdowns.errors.allErrors", value: 'ALL'},
				{label: "dropdowns.errors.edi", value: 'EDI'},
				{label: "dropdowns.errors.email", value: 'EMAIL'},
				{label: "dropdowns.errors.print", value: 'PRINT'}
			]

			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus) {
				channelsOptions.push({label: "dropdowns.errors.payment", value: 'PAYMENT'})
			}
			return channelsOptions
		},
		channels() {
			let optionschannels = []

			if (this.$store.state.supplierGroup.data.channelsConfig?.attachEmail === true) optionschannels.push({
				label: 'dropdowns.channels.attachMail',
				value: 'AttachEmail'
			})
			if (this.$store.state.supplierGroup.data.channelsConfig?.linkEmail === true) optionschannels.push({
				label: 'dropdowns.channels.linkMail',
				value: 'LinkEmail'
			})
			if (this.$store.state.supplierGroup.data.channelsConfig?.print !== undefined) optionschannels.push({
				label: 'dropdowns.channels.print',
				value: 'Print'
			})

			if (this.$store.state.supplierGroup.data.channelsConfig?.edi?.ediChannelProviders) {
				Object.keys(this.$store.state.supplierGroup.data.channelsConfig.edi.ediChannelProviders).forEach(network => {
					optionschannels.push({label: network, value: network})
				})
			}

			optionschannels.sort((a, b) => {
				const textA = a.value
				const textB = b.value
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
			optionschannels.unshift({label: 'dropdowns.channels.noChannel', value: ''})
			return optionschannels
		},
		showErrorChannelsInRowReverse() {
			if (this.showInOneColumn) {
				return ''
			} else if (this.userIsReceiver) {
				return ''
			} else if (!!this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus || !this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus ) {
				return {flexDirection: 'row-reverse'}
			} else return ''
		},
		classesErrorChannels() {
			if (this.showInOneColumn) return 'mb-2.5'
			else if (this.userIsReceiver) return 'mr-2.5'
			else return 'ml-2.5'
		},
		styleErrorChannels() {

			if (this.showInOneColumn) return {width: '100%'}
			else return {width: '50%'}
			// !showInOneColumn || userIsReceiver ?  :
		},
		displayIncludeHandled() {
			let display = false
			if (this.extendedSearch.errorChannels.value === '') {
				this.setIncludeHandledFalse()
			} else display = true

			return display
		},
		paymentStatusOptions() {
			const statuses = [
				{
					label: 'extendedSearch.overview.paymentStatus.open',
					value: 'OPEN'
				},
				{
					label: 'extendedSearch.overview.paymentStatus.paidConfirmed',
					value: 'PAYMENT_CONFIRMED'
				},
				{
					label: 'extendedSearch.overview.paymentStatus.paidOnline',
					value: 'PAID_ONLINE'
				},

				{
					label: 'extendedSearch.overview.paymentStatus.overdue',
					value: 'OVERDUE'
				}
			]

			if (this.$store.state.supplierGroup.data.channelsConfig.payment?.transactionsViaTwikey && this.documentTypes.find(type => type.value === "MANDATE_ONBOARDING")) {
				statuses.push({label: 'extendedSearch.overview.paymentStatus.error', value: 'ERROR'})
				statuses.push({label: 'extendedSearch.overview.paymentStatus.errorTempTran', value: 'ERROR_TEMP_TRAN'})
				statuses.push({label: 'extendedSearch.overview.paymentStatus.pendingTran', value: 'PENDING_TRAN'})
				statuses.push({label: 'extendedSearch.overview.paymentStatus.declined', value: 'DECLINED'})
				statuses.push({label: 'extendedSearch.overview.paymentStatus.paidTran', value: 'PAID_TRAN'})
				statuses.push({label: 'extendedSearch.overview.paymentStatus.errorTran', value: 'ERROR_TRAN'})
			}

			statuses.sort((a, b) => {
				const textA = i18n.t(a.label).toUpperCase()
				const textB = i18n.t(b.label).toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
			statuses.unshift({
				label: 'extendedSearch.overview.paymentStatus.noFilterOnPaymentStatus',
				value: ''
			})
			return statuses
		}
	},
	methods: {
		extendedSearchStartValue() {
			return {
				period: {
					from: null,
					to: null
				},
				legalEntityNumber: {label: 'general.allLegalEntity', value: null},
				documentType: {label: "general.noDocType", value: ''},
				clientId: '',
				documentNumber: '',
				errorChannels: {label: "dropdowns.errors.noErrorChannels", value: ''},
				includeHandled: false,
				paymentStatus: {
					label: 'extendedSearch.overview.paymentStatus.noFilterOnPaymentStatus',
					value: '',
				},
				released: false,
				channel: {label: 'dropdowns.channels.noChannel', value: ''}
			}
		},
		setIncludeHandled() {
			this.extendedSearch.includeHandled = !this.extendedSearch.includeHandled
		},
		setIncludeHandledFalse() {
			this.extendedSearch.includeHandled = false
		},
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	flex-direction: row;
}

.includeHandled {
	display: flex;
	align-items: center;
}

.calendar-icon {
	padding-top: 5px;
	font-size: 20px;
	padding-left: 6px;
	padding-right: 12px;
	color: #355983;
}

.clear-icon {
	position: absolute;
	font-size: 20px;
	right: 15px;
	color: #82858a;
	cursor: pointer;
}

@media only screen and (max-width: 980px) {
	.one-line {
		flex-direction: column;
	}
}
</style>
<template>
	<div class="reprint-container">
		<div class="check-on-peppol mb-2">
			<div class="placement-check-peppol">
				<button
					class="btn custom-button shadow-md text-popup"
					@click="checkIdentifier"
				>
					{{ $t('buttons.checkOnPeppol') }}
				</button>
				<div
					v-if="this.$store.state.peppolIsChecked && displayPeppolResult"
					class="check-on-peppol-text flex flex-column items-center"
				>
					<i
						class="icon-notification mry-2 mx-1"
						:class='{"is-on-peppol" :
							styles.isOnPeppol,"is-not-on-peppol" :
							styles.isNotOnPeppol,"peppol-check-error" :
							styles.peppolCheckError
						}'
					/>
					<p>{{ peppolCheckText }}</p>
				</div>
			</div>
		</div>
		<div class="reprint-container-inner">
			<div class="provider-info" v-if="!noProviders.length">
				<div>
					<div class="one-line" style="margin-bottom: 0">
						<label for="ediProvider">{{ $t('documentDetail.resendDocument.edi.choseEdiProvider') }}:</label>
						<vue-select
							id="ediProvider"
							v-model="ediProvider"
							:options='ediProviders'
							@click="clearErrors"
						>
							<template #no-options="{search}">
								{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
							</template>

							<template #option="option">
								{{ option.label }}
							</template>
							<template #selected-option="option">
								{{ option.label }}
							</template>
						</vue-select>

					</div>
					<p v-if="!networkIsChosen" style="margin-left: 170px" class="error">{{
							$t('errors.requiredField')
						}}</p>
				</div>
				<div class="one-line-flex-check" v-show="displayAddAttachment">
					<p>{{ $t('documentDetail.resendDocument.withAttachments') }}:</p>
					<input
						type="checkbox"
						class="customCheckbox"
						@click="askPopup"
						:checked="displayAttachmentsCheckbox"
					>
				</div>

				<div class="one-line-flex-check" v-show="displayEdiReferences">
					<p>{{ $t('documentDetail.resendDocument.edi.addEdiReferences') }}:</p>
					<input
						type="checkbox"
						class="customCheckbox"
						v-model="ediReferences"
					>
				</div>
				<div class="references ml-4" v-if="ediReferences">
					<div class="one-line-flex">
						<p>{{ $t('documentDetail.resendDocument.edi.receiverEdiReference') }}</p>
						<div class="input-flex">
							<input
								type="text"
								class="form-control text-popup input-flex w-52"
								v-model="this.ediProvider.receiver_edi_reference"
							>
						</div>
					</div>
					<div class="one-line-flex">
						<p>{{ $t('documentDetail.resendDocument.edi.senderEdiReference') }}</p>
						<div class="input-flex">
							<input
								type="text"
								class="form-control text-popup w-52"
								v-model="this.ediProvider.sender_edi_reference"
							>
						</div>
					</div>
				</div>

				<div v-if="!resendFromMain && providerIsNymus" class="one-line-flex-check">
					<p>{{ $t('documentDetail.resendDocument.edi.changeDocAndPoNumber') }}:</p>
					<input
						type="checkbox"
						class="customCheckbox"
						v-model="changeDocAndPoNumber"
					>
				</div>
				<div v-if="changeDocAndPoNumber && providerIsNymus">
					<div class="ml-4">
						<div class="po-number mb-2">
							<p class="mr-2">{{ $t('documentDetail.resendDocument.edi.documentNumber') }}</p>
							<div class="flex flex-row">
								<p class="font-size-14">{{ this.$store.state.documentDetailData.documentNumber }}</p>
								<input
									type="text"
									class="form-control width-input text-popup h-8  ml-0.5"
									v-model="this.documentNumber"
								>
							</div>

						</div>
						<div class="po-number-original">
							<div class="flex flex-row mr-4">
								<p class="mr-2">{{ $t('documentDetail.resendDocument.edi.poNumber') }}</p>
								<input
									type="text"
									id='poNumber'
									class="form-control text-popup h-8 width-input"
									v-model="this.poNumber"
								>
							</div>
							<div v-if="documentReferences.length">
								<div class="font-size-14" v-for="(ref, index) in documentReferences" :key=index>
									<div v-if="ref.text">
										{{ ref.text }}: {{ ref.value }}
									</div>
								</div>
							</div>
						</div>

						<div class="duplicates-outer" v-if="displayUsedDuplicateNumbersTitle">
							<p class="edi-provider">{{ $t('documentDetail.documentStatusesInfo.duplicates') }}</p>
							<div class="duplicates">
								<div class="test" v-if="duplicateNumbers.length">
									<p class="edi-provider edi-provider-sub-item mb-1">
										{{ $t('documentDetail.documentStatusesInfo.duplicateNumbers') }}
									</p>
									<div v-for="(item, index) in duplicateNumbers" :key=index>
										- {{ item }}
									</div>
								</div>

								<div class="test" v-if="duplicatePoNumbers.length">
									<p class="edi-provider edi-provider-sub-item mb-1">
										{{ $t('documentDetail.documentStatusesInfo.duplicatePoNumbers') }}
									</p>
									<div v-for="(item, index) in duplicatePoNumbers" :key=index>
										- {{ item }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="one-line-flex-check" v-show="showEditUBL && providerIsNymus">
					<p> {{ $t('documentDetail.editPeppolIdentifier.title') }}:</p>
					<input
						type="checkbox"
						class="customCheckbox"
						v-model="changePeppolIdentifier"
					>
				</div>
				<div v-if="changePeppolIdentifier">
					<div class="one-item">
						<div class="one-line-flex">
							<p>{{ $t('documentDetail.editPeppolIdentifier.identifierNumber') }}</p>
							<div class="input-flex">
								<input
									type="text"
									class="form-control text-popup input-flex"
									placeholder="The current peppol identifier"
									v-model="this.peppolIdentifier"
									@change="showWrongNewIdentifier = false"
								>
							</div>
						</div>
					</div>
					<p v-if="showWrongNewIdentifier" class="error one-item">{{
							$t('documentDetail.newIdIsNotOnPeppol')
						}}</p>
					<div class="one-item">
						<p>
							{{
								$t('documentDetail.editPeppolIdentifier.originalPeppolIdentifier') + ' ' + this.originalPeppolIdentifier
							}}
						</p>
					</div>
				</div>
			</div>
			<div v-if="!noProviders.length">
				<p class="error error-styling" v-if="errorEdi">{{ $t('errors.requiredField') }}</p>
			</div>
			<div class="no-providers" v-if="noProviders.length">{{ noProviders }}</div>

			<div class="btn-content">
				<div>
					<button class="btn custom-button shadow-md mr-2 text-popup" @click="cancel">
						{{ $t('buttons.cancel') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 text-popup"
							v-if="!noProviders.length"
							@click="resend"
							:disabled='disableButton'
					>
						{{ $t('buttons.resend') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import userGroups from "../../../userGoups"
import VueSelect from "vue3-select"
import i18n from '../../../i18n'

export default {
	components: {VueSelect},
	name: "ResendDocumentEdi",
	emits: ['closePopUp', 'cancel'],
	props: {
		docId: {
			type: Array
		},
		docsData: {
			type: Array
		},
		resendFromMain: Boolean
	},
	mounted() {
		this.checkPoNumber()
		this.setEdiProvider()
		this.getParticipantId()
	},
	data() {
		return {
			disableButton: false,
			userGroups: userGroups,
			ediProvider: {},
			errorEdi: false,
			i18n: i18n,
			ediReferences: false,
			changePeppolIdentifier: false,
			withAttachments: true,
			noProviders: '',
			changeDocAndPoNumber: false,
			poNumber: '',
			documentNumber: "DUPL",
			peppolIdentifier: '',
			originalPeppolIdentifier: '',
			vatNumber: '',
			peppolCheckText: '',
			styles: {
				isOnPeppol: false,
				isNotOnPeppol: false,
				peppolCheckError: false,
			},
			displayPeppolResult: false,
			networkIsChosen: true,
			showWrongNewIdentifier: false
		}
	},
	watch: {
		"$store.state.vatCheck": {
			handler(newVal) {
				if (newVal) {
					if (this.$store.state.vatCheck.status === 'ok') {
						if (this.$store.state.vatCheck.isOnPeppol) {
							this.peppolCheckText = i18n.t('documentDetail.isOnPeppol')
							this.styles.isOnPeppol = true
						} else {
							this.peppolCheckText = i18n.t('documentDetail.isNotOnPeppol')
							this.styles.isNotOnPeppol = true
						}
					} else {
						this.peppolCheckText = i18n.t('documentDetail.peppolCheckError')
						this.styles.peppolCheckError = true
					}
					this.displayPeppolResult = true
				}
			},
			deep: true
		}
	},
	computed: {
		displayAttachmentsCheckbox() {
			if (this.$store.state.documentDetailData?.channels) {
				return !this.$store.state.documentDetailData.channels.archive.attachmentsMerged
			} else {
				let attachmentsAvailable = false
				this.docsData.forEach(doc => {
					if (doc.attachmentsAvailable) attachmentsAvailable = true
				})
				return attachmentsAvailable
			}
		},
		documentReferences() {
			let documentReferences = []
			if (this.$store.state.documentDetailData.billingOptionalData && this.$store.state.documentDetailData.billingOptionalData.documentReferences) {
				documentReferences = this.$store.state.documentDetailData.billingOptionalData.documentReferences
				documentReferences.forEach(item => {
					if (item.description === 'CustomerPOReference') {
						let key = 'documentDetail.resendDocument.edi.' + item.description
						item.text = i18n.t(key)
					}
				})
			}
			return documentReferences
		},
		showEditUBL() {
			if (this.$store.state.documentDetailData?.fileStructure === undefined) {
				let ublsValid = true
				this.docsData.forEach(doc => {
					if (!doc.ublValid) ublsValid = false
				})
				return ublsValid
			}
			return this.$store.state.documentDetailData?.fileStructure?.ublFileName !== undefined
		},
		duplicatePoNumbers() {
			let poNumbers = []
			if (this.$store.state.documentDetailData.channels.edi && this.$store.state.documentDetailData.channels.edi.duplicatePoNumbers) {
				poNumbers = this.$store.state.documentDetailData.channels.edi.duplicatePoNumbers
			}
			return poNumbers
		},
		duplicateNumbers() {
			let numbers = []
			if (this.$store.state.documentDetailData.channels.edi && this.$store.state.documentDetailData.channels.edi.duplicateNumbers) {
				numbers = this.$store.state.documentDetailData.channels.edi.duplicateNumbers
			}
			return numbers
		},
		displayUsedDuplicateNumbersTitle() {
			return !!((this.$store.state.documentDetailData.channels.edi)
				&& (this.$store.state.documentDetailData.channels.edi.duplicateNumbers
					|| this.$store.state.documentDetailData.channels.edi.duplicatePoNumbers))

		},
		displayAddAttachment() {
			return !!(this.$store.state.documentDetailData.fileStructure
				&& this.$store.state.documentDetailData.fileStructure.attachmentsFileNames.length)
		},
		displayEdiReferences() {
			return userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group) && this.providerIsNymus
		},
		providerIsNymus(){
			return this.$store.state.supplierGroup.data.channelsConfig.edi.ediChannelProviders[this.ediProvider?.value]?.provider === "NYMUS_PEPPOL"
		},
		ediProviders() {
			let options = []
			let ediData = this.$store.state.supplierGroup.data.channelsConfig.edi.ediChannelProviders

			let providers = []
			for (const ediItem in ediData) {
				if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)) {
					let tempItem = {
						service_provider: ediItem,
						receiver_edi_reference: "",
						sender_edi_reference: ""
					}
					providers.push(tempItem)
					options.push({label: ediItem, value: ediItem})
				}
				if (userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group) && ediData[ediItem].resendAllowedBySupplier) {
					let tempItem = {
						service_provider: ediItem,
						receiver_edi_reference: "",
						sender_edi_reference: ""
					}
					providers.push(tempItem)
					options.push({label: ediItem, value: ediItem})
				}
			}

			if (providers.length === 0) {
				let tempItem = {
					service_provider: "",
					receiver_edi_reference: "",
					sender_edi_reference: ""
				}
				providers.push(tempItem)
				this.setNoProvider()
				options.push({label: "Nothing to display", value: ''})
			}

			return options
		},
		displayCheckOnPeppol() {
			if (userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) {
				return false
			}
			if (this.$store.state.documentDetailData.fileStructure.ublFileName
				&& (this.$store.state.documentDetailData.billingData?.billingTo?.customerAddress?.countryCode
					&& this.$store.state.documentDetailData.billingData.billingTo.customerAddress.countryCode === "BE")
			) {
				if (this.$store.state.documentDetailData.billingData?.billingTo?.customerIdentification?.vatNumber) {
					this.setVatNumber(this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.vatNumber)
				} else if (this.$store.state.documentDetailData.billingData?.billingTo?.customerIdentification?.registration?.registrationNumber) {
					this.setVatNumber(this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.registration.registrationNumber)
				}
				return true
			}
			return false
		},
		peppolIsChecked() {
			return this.$store.state.peppolIsChecked
		},
	},
	methods: {
		setVatNumber(vat) {
			this.vatNumber = vat
		},
		async checkIdentifier() {
			let status = await this.checkPeppol()

			if (status === 200) {
				this.$store.commit("setVatIsOnPeppol", {isOnPeppol: true, status: "ok"})
			} else if (status === 404) {
				this.$store.commit("setVatIsOnPeppol", {isOnPeppol: false, status: "ok"})
			} else {
				this.$store.commit("setVatIsOnPeppol", {isOnPeppol: false, status: "error"})
			}
			this.displayPeppolResult = true
			this.$store.state.peppolIsChecked = true
		},
		async checkPeppol() {
			let identifier

			if (this.peppolIdentifier.trim() !== '') {
				identifier = this.peppolIdentifier
			} else if (this.originalPeppolIdentifier.trim() !== '') {
				identifier = this.originalPeppolIdentifier
			} else identifier = this.vatNumber

			this.displayPeppolResult = false
			this.styles = {
				isOnPeppol: false,
				isNotOnPeppol: false,
				peppolCheckError: false,
			}
			this.$store.commit("setPeppolIsChecked", false)

			let payload = {
				id: identifier
			}

			let docType = this.$store.state.documentDetailData.documentType
			if (docType === undefined) docType = this.docsData[0].documentData.documentType

			switch (docType) {
				case "INVOICE":
					payload.documentType = "BIS_BILLING_INVOICE_V3"
					break
				case "CREDIT_NOTE":
					payload.documentType = "BIS_BILLING_CREDIT_NOTE_V3"
			}
			return await this.$store.dispatch("peppol/checkOnPeppolResend", payload)
		},
		askPopup() {
			const checkBox = document.getElementById("addAttachCheck")
			if (!this.displayAttachmentsCheckbox && checkBox.checked) {
				return this.$store.commit("displayAskPopUp", {display: true, action: 'resendDocumentMail'})
			}
			return null
		},
		setEdiProvider() {
			if (this.$store.state.documentDetailData?.channels) {
				if (this.$store.state.documentDetailData?.channels.edi?.network) {
					this.ediProvider = {
						label: this.$store.state.documentDetailData.channels.edi.network,
						value: this.$store.state.documentDetailData.channels.edi.network
					}
				}
			} else {
				this.docsData.forEach(doc => {
					if (doc.documentData.channels?.edi?.network) {
						this.ediProvider = {
							label: doc.documentData.channels.edi.network,
							value: doc.documentData.channels.edi.network
						}
					}
				})
			}

			if (this.ediProvider.label === undefined && this.ediProviders.length > 0) {
				this.ediProvider = this.ediProviders.find(el => el.label === 'Peppol')
				if (this.ediProvider === undefined) this.ediProvider = this.ediProviders[0]
			}
		},
		async getParticipantId() {
			let docId = ''
			if (typeof this.docId === 'string') docId = this.docId
			else docId = this.docId[0]
			const res = await this.$store.dispatch('documents/getParticipantId', {docId: docId})
			this.peppolIdentifier = res.data.data.participantId.schemeId + ':' + res.data.data.participantId.receiverId
			this.originalPeppolIdentifier = res.data.data.participantId.schemeId + ':' + res.data.data.participantId.receiverId
		},
		checkPoNumber() {
			if (this.$store.state.documentDetailData.billingOptionalData?.documentReferences?.length) {
				this.$store.state.documentDetailData.billingOptionalData.documentReferences.forEach(reference => {
					if (reference.description === "CustomerPOReference") {
						this.poNumber = reference.value
					}
				})
			}
		},
		setNoProvider() {
			this.noProviders = i18n.t('general.noItemsToDisplay')
		},
		cancel() {
			this.changeDocAndPoNumber = false
			this.poNumber = ''
			this.documentNumber = this.$store.state.documentDetailData.documentNumber + "DUPL"
			this.disableButton = false
			this.$store.commit('displayResendDocument', false)
			this.$emit('cancel')
		},
		async handleErrors() {
			let reason = `Automatically handled, resent via peppol to: ${this.peppolIdentifier}`
			if (this.changeDocAndPoNumber) reason += ` with new PO number ${this.poNumber} with adapted documentnumber ${this.documentNumber}`

			await this.$store.dispatch("documents/handleErrors", {
				formData: {
					docIds: this.docId,
					reason: reason
				},
				routeName: this.$route.name
			})
		},
		async resend() {

			if (this.ediProvider?.value === undefined) {
				this.networkIsChosen = false
				return
			}
			this.$store.commit("displayAskPopUp", {display: false, action: ''})
			this.disableButton = true

			if (this.ediProvider.service_provider === '') {
				this.disableButton = false
				this.errorEdi = true
				return
			}
			if (!this.ediReferences) {
				this.ediProvider.receiver_edi_reference = null
				this.ediProvider.sender_edi_reference = null
			}
			let formData = {
				service_provider: this.ediProvider.value,
				receiver_edi_reference: this.ediProvider.receiver_edi_reference,
				sender_edi_reference: this.ediProvider.sender_edi_reference,
			}

			formData.withAttachments = this.withAttachments

			if (this.originalPeppolIdentifier !== this.peppolIdentifier) {
				const identifier = this.peppolIdentifier.split(':')
				formData.schemeId = identifier[0]
				formData.receiverId = identifier[1]
			}
			else {
				formData.schemeId = this.originalPeppolIdentifier.split(':')[0]
				formData.receiverId = this.originalPeppolIdentifier.split(':')[1]
			}

			let status = undefined
			if (this.$store.state.supplierGroup.data.channelsConfig.edi.ediChannelProviders[formData.service_provider]?.provider === "NYMUS_PEPPOL") {
				status = await this.checkPeppol()
			}

			if (!this.changePeppolIdentifier){
				if (status === 200) {
					this.$store.commit("setVatIsOnPeppol", {isOnPeppol: true, status: "ok"})
				} else if (status === 404) {
					this.$store.commit("setVatIsOnPeppol", {isOnPeppol: false, status: "ok"})
				} else {
					this.$store.commit("setVatIsOnPeppol", {isOnPeppol: false, status: "error"})
				}
				this.displayPeppolResult = true
				this.$store.state.peppolIsChecked = true
			}
			if (status === 404) {
				this.showWrongNewIdentifier = true
				this.disableButton = false
				return
			}

			if (this.changeDocAndPoNumber) {
				if (this.documentNumber !== '') {
					formData.documentNumber = this.$store.state.documentDetailData.documentNumber + this.documentNumber
				}
				if (this.poNumber !== '') {
					formData.poNumber = this.poNumber
				}
			}

			if (this.docsData[0].errorsAtDocument && !this.docsData[0].errorsHandled) {
				await this.handleErrors()
			}

			let payload = {
				formData: formData,
			}
			if (this.docsData.length > 1) {
				let data = JSON.parse(JSON.stringify(payload.formData))
				delete data.docId
				payload.formData = {
					ediInfo: []
				}

				this.docId.forEach(docId => {
					let info = {
						docId: docId,
						receiverId: data.receiverId,
						schemeId: data.schemeId,
						service_provider: data.service_provider,
						withAttachments: data.withAttachments
					}
					payload.formData.ediInfo.push(info)
				})

			} else {
				payload.docId = this.docId[0]
			}

			this.$store.dispatch('documents/resendEdi', payload)

			this.$emit('closePopUp')
		},
		clearErrors() {
			this.errorEdi = false
			this.networkIsChosen = true
		}
	}
}
</script>

<style scoped>
input[type=text] {
	height: 28px;
}

.provider-info {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: repeat(4, auto);
	align-items: center;
	grid-auto-flow: row;
	margin-bottom: var(--generalPaddings);
	padding-bottom: var(--generalPaddings);
	border-bottom: 1px solid var(--theme-primary-50);
}

select {
	line-height: 28px;
}

.no-providers {
	text-align: center;
	margin-bottom: var(--generalPaddings);
	border-bottom: 1px solid var(--theme-primary-50);
}

label {
	font-size: 14px;
}

.one-line {
	display: grid;
	grid-template-columns: 150px calc(100% - 170px);
	grid-template-rows: 100%;
	grid-auto-flow: column;
	grid-column-gap: 20px;
	align-items: center;
	margin-bottom: var(--generalPaddings);
}

.reprint-container-inner {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: repeat(5, auto);
}

.reprint-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows:  auto;
	grid-auto-flow: row;
	margin-top: var(--generalPaddings);
}

.one-item {
	display: flex;
	flex-direction: row;
}

.one-line-flex-check, .one-line-flex {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 6px;
	margin-top: 10px;
}

.po-number {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.po-number-original {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.width-input {
	width: 12rem
}

.input-flex {
	flex-grow: 2
}

.font-size-14 {
	font-size: 14px;
}

.duplicates {
	margin-left: 20px;
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 50% 50%;
	font-size: 14px;
}

.duplicates-outer {
	grid-column: 1/3;
}

.check-on-peppol-text {
	margin-top: 5px;
}

.is-on-peppol {
	color: var(--successeColor);
}

.is-not-on-peppol {
	color: var(--errorColor)
}

.peppol-check-error {
	color: var(--warningColor)
}

.placement-check-peppol {
	display: flex;
	flex-direction: column;
	align-items: end;
}

@media only screen and (max-width: 900px) {
	.po-number-original {
		display: flex;
		flex-direction: column;
		align-items: start !important;
		justify-content: start;
	}
	.one-line {
		grid-column-gap: 0;
		grid-template-columns: 120px calc(115% - 170px);
	}
	.one-line-flex {
		flex-direction: column;
		align-items: start;
	}
	.po-number {
		display: flex;
		flex-direction: column;
		align-items: start;
	}
	.width-input {
		width: 8rem;
	}
}

</style>
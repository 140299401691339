<template>
	<div v-if="displayTable">
		<top-banner>
			<slot v-if="documentDataCheck">
				<h1>{{ header }} </h1>
			</slot>
		</top-banner>
		<document-status-viewer
			:show-in-pop-up="true"
			:doc-status-data="docStatusData"
			:show-statuses="showStatuses"
			@close-doc-statuses="showStatuses = false"
		/>

		<div class="main documentDetail intro-y ">
			<error-notification/>
			<div class="box p-5">
				<action-buttons
					:access-code="accessCode"
					:doc-name="tableData.docName"
					:doc-number="tableData.docNumber"
					:legal-entity-id="tableData.legalEntityId"
					:doc-id="tableData.docId"
					:ubl-valid="tableData.showDownloadUBL"
					:visibility="tableData.visibility"
					:due-date="tableData.dueDate"
					:doc-type="tableData.docType"
					:ubl-file="tableData.ublFile"
					:errors-at-document="tableData.errorsAtDocument"
					:payment="tableData.payment"
				/>
				<document-table
					:tableData="tableData"
					:access-code="accessCode"
				></document-table>
			</div>

			<div v-if="notPublic" class="showStatusesContainer mt-2 mb-2">
				<button class="btn custom-button shadow-md mr-2 showStatuses" @click="showDocInfo">
					<i class="icon-bell mr-3"></i>{{ $t('documentDetail.documentStatusesInfo.documentStatuses') }}
				</button>
			</div>
			<div class="document-container">
				<iframe class="pdfContainer"
						title="document"
						:src=documentPDFLink
						width="100%"
						height="100%"
				>
					<p>Your browser does not support PDFs.</p>
					<a target="_blank" :href=documentPDFLink>
						<button class="btn custom-button w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1">
							View document
							<p>description</p>
						</button>
					</a>
				</iframe>
<!--				<pdf-viewer-->
<!--					:src="documentPDFLink"-->
<!--					:doc-id="documentId"-->
<!--					:doc-name="tableData.docName"-->
<!--					:access-code="accessCode"-->
<!--				/>-->
				<div class="left-side-doc-Info">
					<document-status-viewer
						:show-in-pop-up="false"
						:doc-status-data="docStatusData"
						:show-statuses="showStatuses"
						@close-doc-statuses="showStatuses = false"
						:doc-id="tableData.docId"
						:legal-entity-id="tableData.legalEntityId"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {defineComponent} from "vue"
import TopBanner from "../TopBanner.vue"
import DocumentTable from "./Table.vue"
import i18n from "../../i18n"
import getDocType from "../../mixins/documentTable/calcDocType"
import getPaymentStatus from "../../mixins/documentTable/calcPayStatus"
import setEnvelopIcon from "../../mixins/documentTable/CalcEnvelopIcon"
import calcDueDate from "../../mixins/documentTable/calcDueDate"
import getAmount from "../../mixins/documentTable/calcAmount"
import getAttachments from "../../mixins/documentTable/calcAttachments"
import IsErrorsAtDocument from "../../mixins/documentTable/calcIfErrorsAtDocument"
import getPaymentButton from "../../mixins/documentTable/calcPaymentButton"
import moment from "moment/moment"
import userGroups from "../../userGoups"
import ActionButtons from "./actions/actionButtons.vue"
import DocumentStatusViewer from "./statuses/DocumentStatusViewer.vue"
import mergeAddress from "../../mixins/documentTable/MergeAddress";
import calcChannels from "../../mixins/documentTable/CalcChannels";
import calcCustomerPOReference from "../../mixins/documentTable/calcCustomerPOReference";
import ErrorNotification from "@/components/UI/ErrorNotification.vue";
import PdfViewer from "@/components/UI/PdfViewer.vue";

export default defineComponent({
	name: "DocumentDetailPage",
	components: {PdfViewer, ErrorNotification, DocumentStatusViewer, ActionButtons, DocumentTable, TopBanner},
	data() {
		return {
			documentData: this.$store.state.documentDetailData,
			showStatuses: false,
			documentId: this.$route.params.docId,
			displayTable: false,
			displayPeppolResult: false,
			displayAskPopUp: false,
			styles: {
				isOnPeppol: false,
				isNotOnPeppol: false,
				peppolCheckError: false,
			},
			vatNumber: '',
			accessCode: this.$route.query.access_code ? this.$route.query.access_code : ''
		}
	},
	async mounted() {
		this.$store.commit("displayAskPopUp", {display: false, action: ''})
		if (this.notPublic) this.$store.dispatch("checkAcceptedTos", {requiresAuth: this.$route.meta.requiresAuth})
		this.$store.commit("setResendBatch", false);
		await this.getData()
		if (!this.notPublic) this.$store.dispatch('documents/readPublicDocument', {
			user: 'Anonymous',
			documentId: this.documentData.docId
		})
		this.sendViewRequest()
		this.displayTable = true
	},
	computed: {
		userGroups() {
			return userGroups
		},
		notPublic() {
			return this.$route.name !== 'public'
		},
		documentDataCheck() {
			return this.documentData.docId !== undefined;
		},
		header() {
			let headerText = '';
			let docNumber = this.documentData.opTableInfo ? this.documentData.opTableInfo.document.documentNumber : this.tableData.docNumber
			if (this.$store.state.docType === "GENERAL") {
				headerText = i18n.t('documentDetail.header.general.title') + ' ' + this.tableData.licensePlate;
			}
			if (this.$store.state.docType === "BILLING") {
				headerText = i18n.t('documentDetail.header.billing.title') + ' ' + docNumber;
			}
			if (this.$store.state.docType === "SALARY") {
				headerText = i18n.t('documentDetail.header.salary.title') + ' ' + docNumber;
			}
			return headerText
		},
		displayCheckOnPeppol() {
			if (userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) {
				return false
			}
			if (this.$store.state.documentDetailData.fileStructure.ublFileName
				&& (this.$store.state.documentDetailData.billingData?.billingTo?.customerAddress?.countryCode
					&& this.$store.state.documentDetailData.billingData.billingTo.customerAddress.countryCode === "BE")
			) {
				if (this.$store.state.documentDetailData.billingData?.billingTo?.customerIdentification?.vatNumber) {
					this.setVatNumber(this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.vatNumber)
				} else if (this.$store.state.documentDetailData.billingData?.billingTo?.customerIdentification?.registration?.registrationNumber) {
					this.setVatNumber(this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.registration.registrationNumber)
				}
				return true
			}
			return false
		},
		documentPDFLink() {
			return 'https://' + this.documentData.fileStructure.bucketName + '.storage.googleapis.com/' + this.documentData.fileStructure.opDocumentPortalFileName
		},
		tableData() {
			let tableData = {}
			let documentData = this.$store.state.documentDetailData

			let type = getDocType(documentData, documentData.documentType)
			let docType = ''

			if (type === '') docType = 'NA'
			else if (documentData.documentType.toLowerCase() === "invoice" ||
				documentData.documentType.toLowerCase() === "credit_note" ||
				documentData.documentType.toLowerCase() === "general") {
				docType = i18n.t('billing.' + type)
			} else if (documentData.documentType.toLowerCase() === 'salary') {
				docType = i18n.t('salary.' + type)
			}

			const payment = getPaymentStatus(documentData.channels.payment, calcDueDate(documentData, documentData.opTableInfo), type)

			let logoPath
			if (this.notPublic) {
				if (this.$store.state.legalEntitiesData.data.legalEntities[documentData.legalEntity.entityNumber]?.legalEntityConfig?.logo === undefined) logoPath = ''
				else logoPath = this.$store.state.legalEntitiesData.data.legalEntities[documentData.legalEntity.entityNumber].legalEntityConfig.logo
			} else {
				logoPath = this.$store.state.supplierGroup.data.supplierLegalEntities[documentData.legalEntity.entityNumber].legalEntityConfig ? this.$store.state.supplierGroup.data.supplierLegalEntities[documentData.legalEntity.entityNumber].legalEntityConfig.logo : ''
			}

			let receiverName = ''
			if (documentData.opTableInfo) {
				receiverName = documentData.opTableInfo.receiver.receiverName
			} else if (documentData.generalDocumentData) {
				receiverName = documentData.generalDocumentData.receiverName
			} else if (documentData.billingData?.billingTo) {
				receiverName = documentData.billingData.billingTo.customerIdentification.companyName
			} else if (documentData.salaryData) receiverName = documentData.salaryData.firstName + ' ' + documentData.salaryData.lastName

			if (this.$store.state.docType === "SALARY") {
				tableData.attachmentsFileNames = documentData.fileStructure.attachmentsFileNames
			}

			let docDate
			if (documentData.salaryData !== undefined) docDate = documentData.salaryData.documentDate
			else if (documentData.opTableInfo !== undefined) docDate = documentData.opTableInfo.documentDates.documentDate
			else if (documentData.generalDocumentData !== undefined) docDate = documentData.generalDocumentData.documentDate
			else docDate = documentData.billingData.documentDate

			const downloadName = documentData.fileStructure.opDocumentPortalFileName.split('/')

			let clientId = ''
			if (documentData.billingData?.clientId) {
				clientId = documentData.billingData.clientId
			} else if (documentData.opTableInfo) {
				clientId = documentData.opTableInfo.receiver.receiverId
			} else if (documentData.generalDocumentData) {
				clientId = documentData.generalDocumentData.receiverId
			} else if (documentData.salaryData) clientId = documentData.salaryData.employeeId

			let legalEntityName = documentData.legalEntity.name
			if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
				let le = this.$store.state.legalEntitiesData.data.legalEntities[documentData.legalEntity.entityNumber]
				if (le.internalName) legalEntityName = le.internalName
			}

			tableData = {
				viewPropertiesColumns: setEnvelopIcon(documentData, this.$store.state.currentUserData.data.group),
				logo: logoPath ? logoPath : 'no-logo',
				legalEntityName: legalEntityName,
				docType: docType,
				customerName: receiverName,
				clientId: clientId,
				docNumber: documentData.documentNumber,
				docDate: docDate,
				visibility: documentData.visible ? `<i class="icon icon-eye1 icon-color visibility-doc-icon"></i>` : `<i class="icon icon-eye-blocked icon-color visibility-doc-icon"></i>`,
				dueDate: calcDueDate(documentData, documentData.opTableInfo),
				amount: getAmount(documentData, "totalAmountDue"),
				totalAmountTaxes: getAmount(documentData, "totalAmountTaxes"),
				totalTaxableAmount: getAmount(documentData, "totalTaxableAmount"), payment: payment.payment,
				paymentStatusTooltip: payment.paymentStatusTooltip,
				documentData: documentData.data,
				attachmentFileIcon: getAttachments(documentData.fileStructure.attachmentsFileNames, this.$store.state.docType),
				insertsFileNames: [],
				errorsAtDocument: IsErrorsAtDocument(documentData.channels),
				showDownloadUBL: documentData.fileStructure.ublFileName,
				downloadUBLTooltip: i18n.t('tooltips.downloadXml'),
				language: documentData.billingData?.language ? documentData.billingData.language : (documentData.generalDocumentData?.language ? documentData.generalDocumentData.language : documentData.salaryData.language),
				vatNrReceiver: documentData.billingData?.billingTo?.customerIdentification.vatNumber ? documentData.billingData.billingTo.customerIdentification.vatNumber : '',
				vatNrSender: documentData.billingData?.billingFrom?.vatNumber ? documentData.billingData.billingFrom.vatNumber : '',
				customerPOReference: calcCustomerPOReference(documentData.billingOptionalData?.documentReferences),
				fullAddress: mergeAddress(documentData, type),
				channels: calcChannels(documentData.channels),

				startDate: documentData.generalDocumentData && documentData.generalDocumentData.startDate ? documentData.generalDocumentData.startDate : '',
				endDate: documentData.generalDocumentData && documentData.generalDocumentData.endDate ? documentData.generalDocumentData.endDate : '',
				licensePlate: documentData.carInsuranceData ? documentData.carInsuranceData.licensePlate : '',

				period: documentData.salaryData ? documentData.salaryData.period : '',
				employee: receiverName,

				// resend doc
				docId: documentData.docId,
				legalEntityId: documentData.opTableInfo ? documentData.opTableInfo.legalEntity.legalEntityId : documentData.legalEntity.entityNumber,

				//to download pdf with correct name
				docName: downloadName[downloadName.length - 1],
				// ubl
				ublFile: !!documentData.fileStructure.ublFileName,

				// receiverView
				paymentButton: getPaymentButton(documentData.channels.payment, documentData.documentType),
				paymentButtonTooltip: i18n.t('tooltips.payNow')
			}

			return tableData
		},
		docStatusData() {
			let documentData = JSON.parse(JSON.stringify(this.$store.state.documentDetailData))
			let docStatusData = {}

			//visibility + createdOn
			docStatusData.docStatuses = {
				visibility: documentData.visible,
				createdOn: moment(documentData.createdOn).format('DD/MM/YYYY'),
			};
			//show visibility
			docStatusData.docStatuses.showVisibility = !((this.$store.state.currentUserData.data.group === 'ReceiverUser') || (this.$store.state.currentUserData.data.group === 'ReceiverAdmin'));

			if ((documentData.visible)) {
				docStatusData.docStatuses.visibility = 'visible';
			} else docStatusData.docStatuses.visibility = 'notVisible';

			docStatusData.docType = getDocType(documentData, documentData.documentType)
			//signed
			if (documentData.channels?.archive?.signed !== undefined) docStatusData.signed = documentData.channels.archive.signed
			else {
				if (this.$store.state.supplierGroup.data.channelsConfig.signing?.docTypes) {
					docStatusData.signed = !!this.$store.state.supplierGroup.data.channelsConfig.signing.docTypes.find(type => type === documentData.documentType)
				} else docStatusData.signed = false
			}

			if (documentData.docId) {
				// HandledErrorInfo
				if (documentData.channels.archive?.handledInfos) {
					docStatusData.handledInfo = documentData.channels.archive.handledInfos
				} else if (documentData.channels.archive?.handledInfo) {
					// safety for old method handeling errors
					docStatusData.handledInfo = [documentData.channels.archive.handledInfo]
				}

				if (docStatusData.handledInfo) {
					docStatusData.handledInfo.forEach(info => {
						if (moment(info.handledAt).isValid() ) info.handledAt = moment(info.handledAt).format('DD/MM/YYYY')
					})
				}
//attached  fileNames
				if (documentData.fileStructure.attachmentsFileNames) {
					let fileNames = []
					documentData.fileStructure.attachmentsFileNames.forEach(name => {
						let lastIndex = name.lastIndexOf("/");
						let displayedName = name.slice(lastIndex + 1);
						let tempItem = {
							fullName: name,
							displayedName: displayedName,
							href: 'https://' + documentData.fileStructure.bucketName + '.storage.googleapis.com/' + name,
						}
						fileNames.push(tempItem)
					})

					docStatusData.attachmentFileNames = fileNames
				}
				docStatusData.attachmentMerged = documentData.channels.archive.attachmentsMerged
//insertes fileName
				if (documentData.fileStructure.insertsFileNames || documentData.fileStructure.insertTemplateFileNames) {

					let insertsFileNames = []
					if (documentData.fileStructure.insertsFileNames) {
						documentData.fileStructure.insertsFileNames.forEach(name => {
							let lastIndex = name.lastIndexOf("/");
							let displayedName = name.slice(lastIndex + 1);
							let tempItem = {
								fullName: name,
								displayedName: displayedName,
								href: 'https://' + documentData.fileStructure.bucketName + '.storage.googleapis.com/' + name,
							}
							insertsFileNames.push(tempItem)

						})
					}
					if (documentData.fileStructure.insertTemplateFileNames) {
						documentData.fileStructure.insertTemplateFileNames.forEach(name => {
							let lastIndex = name.lastIndexOf("/");
							let displayedName = name.slice(lastIndex + 1);
							let tempItem = {
								fullName: name,
								displayedName: displayedName,
								href: 'https://' + documentData.fileStructure.bucketName + '.storage.googleapis.com/' + name,
							}
							insertsFileNames.push(tempItem)
						})
					}
					docStatusData.insertsFileNames = insertsFileNames
				}
// Payment info
				if (documentData.channels.payment?.paymentUpdates) {
					docStatusData.paymentUpdatesRaw = documentData.channels.payment.paymentUpdates;
					docStatusData.paymentInfo = documentData.channels.payment.paymentUpdates[documentData.channels.payment.paymentUpdates.length - 1]
				}
//Email with attachemt
				if (documentData.channels.attachEmail) {
					let emailRecipients = []; //all emails
					let inactiveEmail = [];   // inactive Emails
					let sentEmail = [];      // emails which has no errorOn, but not in feedback array
					let deliveredEmail = []; // emails from feedback.delivered
					let errorEmail = [];     // emails from feedback.bounces
					let openEmail = [];    //emails which are opened
					let spam = []          //emails from feedback.spamComplaint

					if (documentData.channels.attachEmail.emailRecipients) {
						documentData.channels.attachEmail.emailRecipients.forEach(item => {
							let tempRecipient = {
								email: item.email,
								procededOn: item.processedOn
							}
							emailRecipients.push(tempRecipient);

							if (item.error) {
								let tempItem = {
									email: item.email,
									time: moment(item.errorOn).format("DD/MM/YYYY"),
									errorReason: item.errorReason ? item.errorReason : item.error,
									errorDescription: item.error
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(item.errorOn).format("DD/MM/YYYY h:mm:ss a")
								}

								inactiveEmail.push(tempItem);
							}
						})
					}

					if (documentData.channels.attachEmail.feedback) {
						if (documentData.channels.attachEmail.feedback.deliveries.length) {
							documentData.channels.attachEmail.feedback.deliveries.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.deliveredAt).format("DD/MM/YYYY")
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.deliveredAt).format("DD/MM/YYYY h:mm:ss a")
								}

								deliveredEmail.push(tempItem);
							})
						}

						if (documentData.channels.attachEmail.feedback.bounces.length) {
							documentData.channels.attachEmail.feedback.bounces.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.bouncedAt).format("DD/MM/YYYY"),
									errorReason: rec.name,
									description: rec.description
								};
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								errorEmail.push(tempItem)
							})
						}

						if (documentData.channels.attachEmail.feedback.spamComplaints.length) {
							documentData.channels.attachEmail.feedback.spamComplaints.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.bouncedAt).format("DD/MM/YYYY"),
								};
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								spam.push(tempItem)
							})
						}
						if (documentData.channels.attachEmail.feedback.opens.length) {
							documentData.channels.attachEmail.feedback.opens.forEach((open) => {
								let tempItem = {
									email: open.recipient,
									time: moment(open.receivedAt).format("DD/MM/YYYY"),
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(open.receivedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								openEmail.push(tempItem)
							})
						}
					}

					let proceededEmailsInfo = [...deliveredEmail, ...errorEmail, ...inactiveEmail, ...spam]
					let proceededEmails = [];
					proceededEmailsInfo.forEach(item => proceededEmails.push(item.email))

					emailRecipients.forEach((rec) => {
						if (!(proceededEmails.includes(rec.email))) {
							let tempItem = {email: rec.email}

							if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
								|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
								tempItem.time = moment(rec.procededOn).format("DD/MM/YYYY h:mm:ss a")
							} else tempItem.time = moment(rec.procededOn).format("DD/MM/YYYY")
							sentEmail.push(tempItem)
						}
					})

					docStatusData.attachMail = {
						sentEmail: sentEmail,
						deliveredEmail: deliveredEmail,
						openEmail: openEmail,
						spam: spam,
						errorEmail: errorEmail,
						inactiveEmail: inactiveEmail
					}
				}
//email with link
				if (documentData.channels.linkEmail) {
					let emailRecipients = []; //all emails
					let inactiveEmail = [];   // emails with errorOn
					let sentEmail = [];      // emails which has no errorOn, but not in feedback array
					let deliveredEmail = []; // emails from feedback.delivered
					let errorEmail = [];     // emails from feedback.bounces
					let openEmail = [];    //emails which are opened
					let spam = []         //emails from feedback.spamComplaint

					if (documentData.channels.linkEmail.emailRecipients) {
						documentData.channels.linkEmail.emailRecipients.forEach(item => {
							let tempRecipient = {
								email: item.email,
								time: moment(item.processedOn).format("DD/MM/YYYY")
							}

							if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
								|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
								tempRecipient.time = moment(item.processedOn).format("DD/MM/YYYY h:mm:ss a")
							}

							emailRecipients.push(tempRecipient)

							if (item.error) {
								let tempItem = {
									email: item.email,
									time: moment(item.errorOn).format("DD/MM/YYYY"),
									errorReason: item.errorReason ? item.errorReason : item.error
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(item.errorOn).format("DD/MM/YYYY h:mm:ss a")
								}

								inactiveEmail.push(tempItem)
							}
						})
					}

					if (documentData.channels.linkEmail.feedback) {
						if (documentData.channels.linkEmail.feedback.deliveries.length) {
							documentData.channels.linkEmail.feedback.deliveries.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.deliveredAt).format("DD/MM/YYYY")
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.deliveredAt).format("DD/MM/YYYY h:mm:ss a")
								}
								deliveredEmail.push(tempItem);
							})
						}

						if (documentData.channels.linkEmail.feedback.bounces.length) {
							documentData.channels.linkEmail.feedback.bounces.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.bouncedAt).format("DD/MM/YYYY"),
									errorReason: rec.name,
									description: rec.description
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}

								errorEmail.push(tempItem)
							})
						}

						if (documentData.channels.linkEmail.feedback.spamComplaints.length) {
							documentData.channels.linkEmail.feedback.spamComplaints.forEach((rec) => {
								let tempItem = {
									email: rec.recipient,
									time: moment(rec.bouncedAt).format("DD/MM/YYYY")
								}

								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(rec.bouncedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								spam.push(tempItem)
							})
						}

						if (documentData.channels.linkEmail.feedback.opens.length) {
							documentData.channels.linkEmail.feedback.opens.forEach((open) => {
								let tempItem = {
									email: open.recipient,
									time: moment(open.receivedAt).format("DD/MM/YYYY"),
								}
								if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
									|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
									tempItem.time = moment(open.receivedAt).format("DD/MM/YYYY h:mm:ss a")
								}
								openEmail.push(tempItem)
							})
						}
					}

					let proceededEmailsInfo = [...deliveredEmail, ...errorEmail, ...inactiveEmail, ...spam];
					let proceededEmails = []

					proceededEmailsInfo.forEach(item => {
						proceededEmails.push(item.email)
					})

					emailRecipients.forEach((rec) => {
						if (!(proceededEmails.includes(rec.email))) {
							let tempItem = {email: rec.email, time: rec.procededOn};
							sentEmail.push(tempItem)
						}
					})

					docStatusData.linkEmail = {
						sentEmail: sentEmail,
						deliveredEmail: deliveredEmail,
						openEmail: openEmail,
						spam: spam,
						errorEmail: errorEmail,
						inactiveEmail: inactiveEmail,
					}
				}
//viewed by
				if (documentData.viewProperties) {
					let receiver = documentData.viewProperties.receiver;

					docStatusData.viewedBy = {
						name: receiver,
						time: moment(documentData.viewProperties.timestamp).format('DD/MM/YYYY')
					}
				}
//edi
				if (documentData.channels.edi && documentData.channels.edi.updates) {
					docStatusData.ediProvider = documentData.channels.edi.network

					let updates = [];
					documentData.channels.edi.updates.forEach(update => {
						let tempItem = {
							time: moment(update.time).format("DD/MM/YYYY"),
							status: update.status
						}
						if (update.message) tempItem.message = update.message
						if (update.resendBy) tempItem.resendBy = update.resendBy

						if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
							|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
							tempItem.time = moment(update.time).format("DD/MM/YYYY h:mm:ss a")
						}
						updates.push(tempItem)
					})
					docStatusData.edi = updates
				}
//print
				if (documentData.channels.print && documentData.channels.print.printStatuses) {
					let print = [];
					documentData.channels.print.printStatuses.forEach((printStatus) => {
						let tempItem = {
							status: printStatus.status.replaceAll('_', '').toLowerCase(),
							message: printStatus.message ? printStatus.message : '',
							time: moment(printStatus.time).format("DD/MM/YYYY")
						};
						if (printStatus.reprintedBy) tempItem.reprintedBy = printStatus.reprintedBy
						print.push(tempItem)
					})
					docStatusData.print = print
				} else if (documentData.channels.print) {
					docStatusData.print = [{
						status: "Queued",
						message: "",
						time: moment(Date.now()).format("DD/MM/YYYY")
					}]
				}

				// Removed channel (after declined hold release)
				if (documentData.channels.removedChannels) {
					docStatusData.removedChannels = documentData.channels.removedChannels
				}

				docStatusData.barCode = {
					code: '',
					registeredMail: false
				}

				if (documentData.channels && documentData.channels.print) {
					if (documentData.channels.print.registeredMail !== undefined) {
						docStatusData.barCode.registeredMail = documentData.channels.print.registeredMail
					}

					if (documentData.channels.print.printJob) {
						this.$store.state.supplierGroup.data.channelsConfig?.print?.printJobs.forEach(job => {
							if (job.name === documentData.channels.print.printJob && job.registeredMail !== undefined) {
								docStatusData.barCode.registeredMail = job.registeredMail
							}
						})
					}
					if (documentData.channels.print.additionalChannelInfo && documentData.channels.print.additionalChannelInfo.code) {
						docStatusData.barCode.code = documentData.channels.print.additionalChannelInfo.code;
					}
				}

				docStatusData.publicLink = '';

				let documentLanguage = this.$store.state.locale
				if (documentData.generalDocumentData?.language) documentLanguage = documentData.generalDocumentData.language.toLowerCase()
				else if (documentData.billingData?.language) documentLanguage = documentData.billingData.language.toLowerCase()
				else if (documentData.salaryData?.language) documentLanguage = documentData.salaryData.language.toLowerCase()

				docStatusData.publicLink = window.location.protocol + "//" + window.location.hostname + "/" + documentLanguage + "/documents/public/" + documentData.docId + "?access_code=" + documentData.channels.archive.accessCode;

				docStatusData.onboardingLink = '';
				if (documentData.channels.payment && documentData.channels.payment.mandateOnboardingLink) {
					let accessCode = documentData.channels.payment.mandateOnboardingLink;
					if (accessCode.includes('/')) {
						let foundIndex = accessCode.lastIndexOf('/');
						accessCode = accessCode.slice(foundIndex + 1);
					}

					docStatusData.onboardingLink = window.location.hostname + '/' + documentLanguage + '/onboarding/' + accessCode;
				}

				docStatusData.channelChoiceLink = '';
				if (documentData.channels.archive && documentData.channels.archive.channelChoiceLink) {
					let accessCode = documentData.channels.archive.channelChoiceLink;
					if (accessCode.includes('/')) {
						let foundIndex = accessCode.lastIndexOf('/');
						accessCode = accessCode.slice(foundIndex + 1);
					}

					let language = 'EN'
					if (this.$store.state.docType === "BILLING" && documentData.billingData?.language) language = documentData.billingData.language
					else if (this.$store.state.docType === "SALARY" && documentData.salaryData?.language) language = documentData.salaryData.language
					else if (this.$store.state.docType === "GENERAL" && documentData.generalDocumentData?.language) language = documentData.generalDocumentData.language
					docStatusData.channelChoiceLink = window.location.hostname + '/' + language.toLowerCase() + '/channel-choice/' + accessCode;
				}

				if (documentData.mandateOnboardingData) {
					docStatusData.validityEnd = documentData.mandateOnboardingData.validityEnd
				}

				docStatusData.zipCode = '';
				if (documentData.channels.print?.postalAddress?.zip) {
					docStatusData.zipCode = documentData.channels.print.postalAddress.zip
				} else if (documentData.billingData?.billingFrom?.address?.zip) {
					docStatusData.zipCode = documentData.billingData.billingFrom.address.zip
				}
			}
			return docStatusData

		},
	},
	methods: {
		async getData() {
			if (this.documentData.docId === undefined) {
				if (this.notPublic) {

					let currentUserId = this.$store.state.currentUserData.data.id;

					await this.$store.dispatch('updateToken')
					await this.$store.dispatch('documents/getDocumentDetailData', {
						userId: currentUserId,
						documentId: this.documentId
					})
					await this.$store.dispatch('fetchLegalEntitiesData', {
						sgId: this.$store.state.supplierGroup.data.supplierGroupId
					})
					await this.$store.dispatch('fetchSupplierGroupBranding', {
						sgId: this.getBrandingOrSgId()
					})

				}
				else {
					await this.$store.dispatch('documents/fetchPublicDocumentData', {
						accessCode: this.$route.query.access_code,
						publicDocId: this.$route.params.docId
					});
				}
				this.documentData = this.$store.getters.getDocumentDetailData;
			}
		},
		sendViewRequest() {
			if (this.$store.state.currentUserData.data.group === 'ReceiverUser' || this.$store.state.currentUserData.data.group === 'ReceiverAdmin') {
				this.$store.dispatch('documents/readDocument', {
					userEmail: this.$store.state.currentUserData.data.email,
					documentId: this.documentData.docId,
				})
			}
		},
		showDocInfo() {
			this.$store.commit('setPageYScrollPosition', window.pageYOffset)
			this.showStatuses = !this.showStatuses
		},

		setVatNumber(vat) {
			this.vatNumber = vat
		},
		getBrandingOrSgId() {
			if (this.$store.state.supplierGroup.data.brandingId) {
				return this.$store.state.supplierGroup.data.brandingId
			} else {
				return this.$store.state.supplierGroup.data.supplierGroupId
			}
		}
	}
})
</script>

<style scoped>
.main {
	min-height: calc(100vh - var(--topBannerHeight) - var(--footerHeight));
}

.document-container {
	margin-top: 5px;
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 65% 35%;
	grid-auto-flow: column;
}

.pdfContainer {
	min-height: calc(100vh - var(--topBannerHeight) - var(--footerHeight));
	width: 100%;
}

.showStatuses {
	display: none;
}

.showStatusesContainer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-items: start;
}

.left-side-doc-Info .button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
	margin-bottom: 10px;
}


@media only screen and (max-width: 450px) {
	.showStatusesContainer {
		grid-template-columns: 100%;
		grid-template-rows: auto auto;
		grid-row-gap: 10px;
	}
}

@media only screen and (max-width: 1000px) {
	.showStatuses {
		display: inline-flex;
	}

	.left-side-doc-Info {
		display: none;
	}

	.document-container {
		grid-template-columns: 100%;
		grid-template-rows: 100%;
		grid-auto-flow: row;
	}
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
	.status-title h1 {
		font-size: 1.3rem;
	}
}


@media only screen and (max-width: 1450px) {
	.pdfContainer {
		padding-left: 0;
	}
}
</style>
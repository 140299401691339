<template>
	<div class="mt-3.5">
		<!--Legal entity & docType-->
		<div class="one-line w-full mb-2.5">
			<vue-select
				v-model="extendedSearch.departmentCode"
				:options=departmentCodeOptions
				:clearable="false"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
				<template #option="option">
					{{ option.value === '' ? $t(option.label) : option.label }}
				</template>
				<template #selected-option="option">
					{{ option.value === '' ? $t(option.label) : option.label }}
				</template>
			</vue-select>
			<vue-select
				v-model="extendedSearch.batch"
				:options=this.$store.state.postDrop.pendingBatches.ids
				:clearable="false"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:class="showInOneColumn ? '' : 'ml-2.5'"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
				<template #option="option">
					{{ option.value === '' ? $t(option.label) : option.label }}
				</template>
				<template #selected-option="option">
					{{ option.value === '' ? $t(option.label) : option.label }}
				</template>
			</vue-select>

		</div>
	</div>
</template>
<script>

import VueSelect from "vue3-select"

export default {
	components: {VueSelect},
	data() {
		return {
			showInOneColumn: window.innerWidth < 980,
			extendedSearch: {}
		}
	},
	created() {
		this.extendedSearch = this.extendedSearchStartValue
	},
	mounted() {
		// We are doing it like this, so it doesn't update the main class without needing to press search
		for (let filterElement in this.$store.state.extendedSearchValue.extendedSearch) {
			if (this.$store.state.extendedSearchValue.extendedSearch[filterElement] !== undefined) {
				this.extendedSearch[filterElement] = this.$store.state.extendedSearchValue.extendedSearch[filterElement]
			}
		}

		window.addEventListener('resize', () => this.showInOneColumn = 'change var')
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler() {
				this.extendedSearch = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch))
				if (!this.extendedSearch.departmentCode) this.extendedSearch.departmentCode = {label: 'dropdowns.postDrop.departmentCode', value: ''}
				if (!this.extendedSearch.batch) this.extendedSearch.batch = this.$store.state.postDrop.pendingBatches.ids[0]
			},
			deep: true
		},
		"$store.state.locale": {
			handler(){
				if (this.extendedSearch.batch.value === '') this.extendedSearch.batch.label = 'dropdowns.postDrop.batch'
			}
		},
		showInOneColumn() {
			this.showInOneColumn = window.innerWidth < 980
		}
	},
	computed: {
		departmentCodeOptions() {
			let options = [{label: 'dropdowns.postDrop.departmentCode', value: ''}]
			this.$store.state.userDepartmentCodeList.forEach((department) => {
				options.push(department)
			})
			return options
		},
		extendedSearchStartValue() {
			this.$store.state.postDrop.pendingBatches.ids[0].label = 'dropdowns.postDrop.batch'
			return {
				departmentCode: {label: 'dropdowns.postDrop.departmentCode', value: ''},
				batch: this.$store.state.postDrop.pendingBatches.ids[0]
			}
		}
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 980px) {
	.one-line {
		flex-direction: column;
	}
}
</style>
<template>
	<div class="flex flex-col">
		<button
			v-if="showExtendedSearchButton && displayForInvoice"
			style="flex-basis: 15%"
			class="btn custom-button w-full shadow-md mr-2"
			@click="showFilter = !showFilter"
		>
			Filter
		</button>
		<transition>
			<div v-if="showFilter" class="shadow-lg filter-box"
				 :class="[twoColumns ? 'widthTwoColumns' : 'widthOneColumn']">
				<div style="position:absolute; right: 5px; top: 2px; cursor: pointer" @click="showFilter = false">
					<span class="material-icons">close</span>
				</div>
				<div>
					<!--Overview-->
					<filter-overview v-if="this.currentPage === 'overview'" ref="filterValues"/>
					<!--Peppol-->
					<filter-peppol-overview v-else-if="this.currentPage === 'peppolOverview'" ref="filterValues"/>
					<!--Management-->
					<filter-users v-else-if="this.currentPage === 'users'" ref="filterValues"/>
					<filter-clients v-else-if="this.currentPage === 'clients'" ref="filterValues"/>
					<filter-inactive-email v-else-if="this.currentPage === 'inactiveEmails'" ref="filterValues"/>
					<filter-department v-if="this.currentPage === 'departments'" ref="filterValues"/>
					<!--Payment-->
					<filter-failed-transactions v-else-if="this.currentPage === 'failedTransactions'" ref="filterValues"/>
					<filter-mandates v-else-if="this.currentPage === 'mandates'" ref="filterValues"/>
					<!--Nymdrop-->
					<filter-nymdrop v-else-if="this.currentPage === 'nymdrop'" ref="filterValues"/>
					<filteruploaded-files v-else-if="this.currentPage === 'uploadedFiles'" ref="filterValues"/>
				</div>
				<div class="flex flex-row w-full justify-center">
					<button
						type="button"
						class="btn custom-button shadow-md mr-2.5"
						@click="resetFilter"
					>
						{{ $t('buttons.reset') }}
					</button>
					<button
						type="button"
						class="btn custom-button shadow-md ml-2.5"
						@click="search"
					>
						{{ $t('buttons.search') }}
					</button>
				</div>
			</div>
		</transition>
	</div>

</template>

<script>

import FilterOverview from "../extendedSearch/overview/FilterOverview.vue"
import userGroups from "../../userGoups"
import FilterUsers from "../extendedSearch/management/FilterUsers.vue"
import FilterClients from "../extendedSearch/management/FilterClients.vue"
import FilterInactiveEmail from "../extendedSearch/management/FilterInactiveEmail.vue"
import FilterFailedTransactions from "../extendedSearch/payments/FilterFailedTransactions.vue"
import FilterPeppolOverview from "../extendedSearch/peppol/FilterPeppolOverview.vue"
import FilterDepartment from "../extendedSearch/management/FilterDepartment.vue"
import FilterNymdrop from "./nymdrop/FilterNymdrop.vue"
import FilterMandates from "./payments/FilterMandates.vue";
import moment from "moment";
import FilteruploadedFiles from "@/components/extendedSearch/nymdrop/FilteruploadedFiles.vue";

export default {
	emits: ['resetFilter', 'search'],
	components: {
		FilteruploadedFiles,
		FilterMandates,
		FilterNymdrop,
		FilterDepartment,
		FilterPeppolOverview,
		FilterFailedTransactions, FilterClients, FilterUsers, FilterOverview, FilterInactiveEmail
	},
	props: {
		currentPage: String,
		twoColumns: {
			type: Boolean,
			required: false,
			default: true
		},
		marginLeftBigScreen: {
			type: Number,
			required: false,
			default: 270
		},
		marginLeftSmallScreen: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data() {
		return {
			showFilter: false,
			usedResetButtonInFilter: false
		}
	},
	created() {
		if (this.$store.state.extendedSearchValue.lastPageExtendedSearch !== this.currentPage) {
			this.$store.state.extendedSearchValue.extendedSearch = {}
			if (this.currentPage === "overview") {
				this.$store.state.extendedSearchValue.extendedSearch.legalEntityNumber = {
					label: 'general.allLegalEntity',
					value: null
				}
			}
		}
		this.$store.state.extendedSearchValue.lastPageExtendedSearch = this.currentPage
		this.updateCssVariables();
		window.addEventListener('resize', this.updateCssVariables)
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler(newValue) {
				if (this.usedResetButtonInFilter === false && this.$refs.filterValues?.extendedSearch !== undefined) {
					if (this.$refs.filterValues.extendedSearch !== newValue) this.showFilter = false
				}
				this.usedResetButtonInFilter = false
			},
			deep: true
		}
	},
	computed: {
		showExtendedSearchButton() {
			if (this.$store.state.docType === "GENERAL") return false
			else if (this.currentPage === "overview") return true
			else if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group) || userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
				return true
			}
			return false
		},
		displayForInvoice() {
			return this.$store.state.supplierGroup.data.sgType !== "SALARY"
		}
	},
	methods: {
		updateCssVariables() {
			const root = document.documentElement
			const width = window.innerWidth

			if (width <= 739) {
				root.style.setProperty('--left-position', this.marginLeftSmallScreen.toString())
			} else {
				root.style.setProperty('--left-position', this.marginLeftBigScreen.toString())
			}
		},
		resetFilter() {
			this.usedResetButtonInFilter = true
			this.$store.state.extendedSearchValue.extendedSearch = {}
			this.$emit('resetFilter')
		},
		search() {
			this.$store.state.extendedSearchValue.extendedSearch = {}

			switch (this.currentPage) {
				case "overview":
					this.searchOverview()
					break
				case "users":
					this.searchUsers()
					break
				case "clients":
					this.searchClients()
					break
				case "inactiveEmails":
					this.searchInactiveEmails()
					break
				case "failedTransactions":
					this.searchFailedTransactions()
					break
				case "peppolOverview":
					this.searchPeppolOverview()
					break
				case "departments":
					this.searchDepartments()
					break
				case "nymdrop":
					this.searchNymdrop()
					break
				case "uploadedFiles":
					this.searchUploadedFiles()
					break
				case "mandates":
					this.searchMandates()
			}
			this.showFilter = false
			this.$emit('search')
		},
		searchOverview() {
			let extendedSearch = this.$refs.filterValues.extendedSearch
			if (extendedSearch.period.from !== null && extendedSearch.period.to !== null) {
				this.$store.state.extendedSearchValue.extendedSearch.period = extendedSearch.period
			}

			if (extendedSearch.legalEntityNumber.value !== null) {
				this.$store.state.extendedSearchValue.extendedSearch.legalEntityNumber = extendedSearch.legalEntityNumber
			}

			if (extendedSearch.documentType.value !== "") {
				this.$store.state.extendedSearchValue.extendedSearch.documentType = extendedSearch.documentType
			}

			if (extendedSearch.clientId) {
				this.$store.state.extendedSearchValue.extendedSearch.clientId = extendedSearch.clientId.trim()
			}

			if (extendedSearch.documentNumber) {
				this.$store.state.extendedSearchValue.extendedSearch.documentNumber = extendedSearch.documentNumber.trim()
			}

			if (extendedSearch.errorChannels.value !== "") {
				this.$store.state.extendedSearchValue.extendedSearch.errorChannels = extendedSearch.errorChannels
			}

			if (extendedSearch.paymentStatus.value !== "") {
				this.$store.state.extendedSearchValue.extendedSearch.paymentStatus = extendedSearch.paymentStatus
			}

			this.$store.state.extendedSearchValue.extendedSearch.includeHandled = extendedSearch.includeHandled
			if (extendedSearch.released) this.$store.state.extendedSearchValue.extendedSearch.released = extendedSearch.released

			if (extendedSearch.channel) this.$store.state.extendedSearchValue.extendedSearch.channel = extendedSearch.channel
		},
		searchUsers() {
			this.$store.state.extendedSearchValue.extendedSearch.role = this.$refs.filterValues.extendedSearch.selectedRole
		},
		searchClients() {
			this.$store.state.extendedSearchValue.extendedSearch.legalEntityNumber = this.$refs.filterValues.extendedSearch.legalEntityNumber
			this.$store.state.extendedSearchValue.extendedSearch.selectedChannels = this.$refs.filterValues.extendedSearch.selectedChannels
			this.$store.state.extendedSearchValue.extendedSearch.clientDocsOnHold = this.$refs.filterValues.extendedSearch.clientDocsOnHold
		},
		searchInactiveEmails() {
			let extendedSearch = this.$refs.filterValues.extendedSearch
			if (extendedSearch.period.from !== null && extendedSearch.period.to !== null) {
				this.$store.state.extendedSearchValue.extendedSearch.period = extendedSearch.period
			} else delete this.$store.state.extendedSearchValue.extendedSearch.period

			if (extendedSearch.reason.value !== null) {
				this.$store.state.extendedSearchValue.extendedSearch.reason = extendedSearch.reason
			}
		},
		searchFailedTransactions() {
			let extendedSearch = this.$refs.filterValues.extendedSearch
			if (extendedSearch.transactionId) {
				this.$store.state.extendedSearchValue.extendedSearch.transactionId = extendedSearch.transactionId.trim()
			}
			if (extendedSearch.receiverId) {
				this.$store.state.extendedSearchValue.extendedSearch.receiverId = extendedSearch.receiverId.trim()
			}
			if (extendedSearch.documentNumber) {
				this.$store.state.extendedSearchValue.extendedSearch.documentNumber = extendedSearch.documentNumber.trim()
			}
			if (extendedSearch.mandateId) {
				this.$store.state.extendedSearchValue.extendedSearch.mandateId = extendedSearch.mandateId.trim()
			}
			if (extendedSearch.errorCode.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.errorCode = extendedSearch.errorCode
			}
			if (extendedSearch.handled.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.handled = extendedSearch.handled
			}
		},
		searchMandates(){
			let extendedSearch = this.$refs.filterValues.extendedSearch

			if (extendedSearch.legalEntityIds?.length > 0) {
				this.$store.state.extendedSearchValue.extendedSearch.legalEntityIds = []
				extendedSearch.legalEntityIds.forEach(le => {
					this.$store.state.extendedSearchValue.extendedSearch.legalEntityIds.push(le)
				})
			}
			if (extendedSearch.mandateId){
				this.$store.state.extendedSearchValue.extendedSearch.mandateId = extendedSearch.mandateId
			}
			if (extendedSearch.receiverId){
				this.$store.state.extendedSearchValue.extendedSearch.receiverId = extendedSearch.receiverId
			}
			if (extendedSearch.status){
				this.$store.state.extendedSearchValue.extendedSearch.status = extendedSearch.status
			}
			if (extendedSearch.updatedAfter) {
				let date = new Date(extendedSearch.updatedAfter)
				this.$store.state.extendedSearchValue.extendedSearch.updatedAfter = moment(date)
			}
		},
		searchPeppolOverview() {
			let extendedSearch = this.$refs.filterValues.extendedSearch
			if (extendedSearch.senderId) {
				this.$store.state.extendedSearchValue.extendedSearch.senderId = extendedSearch.senderId.trim()
			}
			if (extendedSearch.senderName) {
				this.$store.state.extendedSearchValue.extendedSearch.senderName = extendedSearch.senderName.trim()
			}
			if (extendedSearch.receiverId) {
				this.$store.state.extendedSearchValue.extendedSearch.receiverId = extendedSearch.receiverId.trim()
			}
			if (extendedSearch.receiverName) {
				this.$store.state.extendedSearchValue.extendedSearch.receiverName = extendedSearch.receiverName.trim()
			}
			if (extendedSearch.documentNumber) {
				this.$store.state.extendedSearchValue.extendedSearch.documentNumber = extendedSearch.documentNumber.trim()
			}

			if (extendedSearch.direction.value !== null) {
				this.$store.state.extendedSearchValue.extendedSearch.direction = extendedSearch.direction
			}
			if (extendedSearch.peppolMessageType.value !== null) {
				this.$store.state.extendedSearchValue.extendedSearch.peppolMessageType = extendedSearch.peppolMessageType
			}
			if (extendedSearch.rejectedImrOnly) {
				this.$store.state.extendedSearchValue.extendedSearch.rejectedImrOnly = extendedSearch.rejectedImrOnly
			}
			if (extendedSearch.retrieved.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.retrieved = extendedSearch.retrieved
			}
		},
		searchDepartments() {
			let extendedSearch = this.$refs.filterValues.extendedSearch
			if (extendedSearch.userId.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.userId = extendedSearch.userId
			}
		},
		searchNymdrop(){
			let extendedSearch = this.$refs.filterValues.extendedSearch
			if (extendedSearch.departmentCode.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.departmentCode = extendedSearch.departmentCode
			}
			if (extendedSearch.printStatus.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.printStatus = extendedSearch.printStatus
			}
		},
		searchUploadedFiles(){
			let extendedSearch = this.$refs.filterValues.extendedSearch

			if (extendedSearch.departmentCode.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.departmentCode = extendedSearch.departmentCode
			}
			if (extendedSearch.batch.value !== '') {
				this.$store.state.extendedSearchValue.extendedSearch.batch = extendedSearch.batch
			}
		}
	}
}
</script>

<style scoped>
:root {
	--left-position: 0px; /* Initialize to avoid linter warnings */
}

.filter-box {
	position: absolute;
	left: var(--left-position) !important;
	margin-top: 36px;
	background-color: white;
	border: solid 1px #f0eded;
	border-radius: 5px;
	z-index: 100;
	padding: 10px
}

/*Do not remove unused widthToColumns or widthOneColumn. It IS used*/
.widthTwoColumns {
	width: 850px;
}

.widthOneColumn {
	width: 350px
}

@media only screen and (max-width: 1250px) {
	.widthTwoColumns {
		width: 600px;
	}
}

@media only screen and (max-width: 1029px) {
	.filter-box {
		left: 275px
	}

	.widthTwoColumns {
		width: 600px;
	}
}

@media only screen and (max-width: 980px) {
	.filter-box {
		left: 275px
	}

	.widthTwoColumns {
		width: 350px;
	}
}

@media only screen and (max-width: 739px) {
	.filter-box {
		left: 0;
	}

	.widthTwoColumns {
		width: 350px;
	}
}

/* animation (css is used) */
.v-enter-active,
.v-leave-active {
	transition: opacity 0.4s ease
}

.v-enter-from,
.v-leave-to {
	opacity: 0
}
</style>
<template>
	<transition name="topAndBottomAnimation">
		<div class="header-height">
			<div>
				<header>
					<div class="top-menu">
						<div class="headerLogo">
							<img :src='brandSettings.logoUrl' alt="company logo" @click="goToOverview"
								 style="cursor: pointer">
						</div>
						<div v-if="isStaging" class="staging" :class="{'showStaging' : isStaging}">
							STAGING
						</div>
						<div class="flex flex-row items-center">
							<select @change="changeLocale" id="lang">
								<option v-for="lang in appLanguages" :value=lang.toLowerCase() :key="lang">
									{{ lang }}
								</option>
							</select>
							<div class="profile pointer"
								 @click="toggleProfile"
								 v-if="showAtPublic && userHasEmail && !SamlLogin">
								{{ $t('profile.title') }}
							</div>
						</div>
					</div>
					<div v-if="smallScreen" @click="toggleOpenMenu" class="nav-container">
						<input class="checkbox" type="checkbox" id="menu-checkbox"/>
						<div class="hamburger-lines">
							<span class="line line1"></span>
							<span class="line line2"></span>
							<span class="line line3"></span>
						</div>
					</div>
					<transition name="mobileMenuTransition">
						<div v-if="openMenu" class="menu-mobile">
							<div class="menu-mobile-line-container">
								<the-navigation
									style="width: 100%;"
									@close-menu="toggleOpenMenu"
								/>
							</div>
						</div>
					</transition>
					<nav v-if="!smallScreen">
						<the-navigation style="margin-top: 8px"/>
					</nav>
				</header>
				<reset-password>
				</reset-password>
				<transition name="dialog">
					<profile-settings
						@closeProfile="toggleProfile"
						v-if="showProfile"
						@editProfile="showEditProfile"
						@resetLoggedInUserPassword='showResetPassword'
					/>
				</transition>
				<transition name="dialog">
					<edit-profile
						@close=closeEditProfile
						v-show="editProfile"
					/>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>
import ProfileSettings from "./ProfileSettings.vue"
import routerData from "../routerData"
import {useI18n} from "vue3-i18n"
import EditProfile from "./EditProfile"
import userGroups from "../userGoups"
import ResetPassword from './management/users/ResetPassword.vue'
import store from "../store"
import router from "@/router"
import TheNavigation from "@/components/TheNavigation.vue";

const newLoginPage = window.newLoginPage
export default {
	name: "TheHeader",
	components: {EditProfile, ProfileSettings, ResetPassword, TheNavigation},
	created() {
		this.getPublicDocumentData()
	},
	mounted() {
		this.setUserLang()
		window.addEventListener('resize', () => {

			if (!this.resizing) {
				this.resizing = true
				setTimeout(() => {
					this.smallScreen = window.innerWidth < 850
				}, 100)
				this.resizing = false
			}
		})
	},
	data() {
		return {
			routerData: routerData,
			userGroups: userGroups,
			showProfile: false,
			i18n: useI18n(),
			supplierGroupId: false,
			languages: [],
			editProfile: false,
			appLanguages: this.$store.state.supplierGroup.data.languages,
			externalLink: {
				text: '',
				link: ''
			},
			smallScreen: window.innerWidth < 850,
			resizing: false,
			openMenu: false
		}
	},
	watch: {
		locale(newVal) {
			this.i18n.setLocale(newVal)
			this.setLang(newVal)
			document.getElementById('lang').setAttribute('value', newVal)
		},
		SG(newVal) {
			this.appLanguages = newVal.languages.sort()
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.currentUserData
		},
		isStaging() {
			return window.location.hostname.includes('localhost') || window.location.hostname.includes('staging')
		},
		locale() {
			return this.$store.state.locale
		},
		showAtPublic() {
			return this.$store.state.userIsAuthenticated
		},
		brandSettings() {
			return this.$store.state.supplierGroup.branding
		},
		SG() {
			return this.$store.state.supplierGroup.data
		},
		userHasEmail() {
			return this.$store.state.currentUserData.data.email !== null && this.$store.state.currentUserData.data.email !== ''
		},
		SamlLogin() {
			return (localStorage.getItem("saml") === "true" && this.$store.state.currentUserData.data.group === "ReceiverUser")
		}
	},

	methods: {
		toggleOpenMenu() {
			this.openMenu = !this.openMenu
			document.getElementById('menu-checkbox').checked = this.openMenu
		},
		updateToken() {
		},
		goToOverview() {
			router.push('/overview')
		},
		setUserLang() {
			if (this.$route.name === 'public' || this.$route.name === 'onboarding' || this.$route.name === "channelChoice") {
				this.$store.state.locale = this.$route.params.lang
			} else if (this.$store.state.currentUserData.data.lastLogin !== "") {
				this.$store.state.locale = this.$store.state.currentUserData.data.language.toLowerCase()
			} else this.$store.state.locale = 'en'
			if (this.$store.state.locale === '') this.$store.state.locale = 'en'
			this.setLang(this.$store.state.locale)
		},

		checkRootName() {
			if (this.$route.name === undefined) {
				setTimeout(() => {
					this.checkRootName()
				}, 50)
				return
			}
			return this.$route.name
		},
		getPublicDocumentData() {
			if (window.location.href.includes('localhost')) {
				this.$store.state.localHostUrl = true
			}
			if (window.location.href.includes('public')) {
				this.$store.state.publicDocumentId = window.location.href.split('/').pop().split('?')[0]
			}
		},
		closeEditProfile() {
			this.editProfile = false
		},
		showEditProfile() {
			this.editProfile = true
			this.showProfile = false
		},
		showResetPassword() {
			this.$store.commit('resetLoggedInUserPassword', true)
			this.$store.commit('displayResetPassword', true)
			this.showProfile = false
		},
		setLang(lang) {
			let langs = document.querySelector("#lang").options
			if (lang === null) langs = "EN"
			if (langs.length === 0) {
				setTimeout(this.setLang, 100, lang)
				return
			}
			let foundLanguage = false
			for (let i = 0; i < langs.length; i++) {
				if (langs[i].value === lang.toLowerCase()) {
					langs[i].selected = true
					foundLanguage = true
					return
				}
			}
			if (!foundLanguage) this.$store.state.locale = 'en'
		},
		changeLocale(event) {
			this.i18n.setLocale(event.target.value)
			this.$store.commit('setLocale', event.target.value)
		},
		toggleProfile() {
			this.$store.dispatch("updateToken")
			this.showProfile = !this.showProfile
		}
	}
}
</script>

<style scoped>

.nav-container {
	margin-top: 5px;
	display: block;
	position: relative;
	left: 93%;
	height: 30px;
	z-index: 50;
}

.nav-container .checkbox {
	position: absolute;
	display: block;
	height: 24px;
	width: 30px;
	left: -7px;
	z-index: 5;
	opacity: 0;
	cursor: pointer;
}

.hamburger-lines {
	height: 14px;
	width: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.hamburger-lines .line {
	display: block;
	height: 2px;
	width: 100%;
	border-radius: 10px;
	background: #0e2431;
}


.nav-container .hamburger-lines .line1 {
	transform-origin: 0% 0%;
	transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
	transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
	transform-origin: 0% 100%;
	transition: transform 0.4s ease-in-out;
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
	transform: rotate(40deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
	transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
	transform: rotate(-40deg);
}

.headerLogo {
	align-self: start;
	z-index: 2;
}

.mobileMenuTransition-enter-active, .mobileMenuTransition-leave-active {
	transition: transform 0.8s ease;
}

.mobileMenuTransition-enter-from, .mobileMenuTransition-leave-to {
	transform: translateX(100%);
}

.mobileMenuTransition-enter-to {
	transform: translateX(0%);
}

.menu-mobile {
	position: fixed;
	background-color: white;
	opacity: 0.90;
	height: 100%;
	width: 50%;
	top: 0;
	right: 0;
	z-index: 49;
}

.menu-mobile-line-container {
	font-size: 20px;
	color: var(--theme-primary-500);
	margin-top: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 700px;
}

.top-menu {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.profile {
	padding-left: calc(2 * var(--generalPaddings));
}

.pointer {
	cursor: pointer;
}

.top-menu select {
	background-color: white;
	border-radius: 4px;
	padding: calc(0.3 * var(--generalPaddings));
}

.staging {
	font-weight: bold;
	color: var(--errorColor);
	align-self: center;
	font-size: 20px;
	border: 3px solid var(--errorColor);
	padding: 4px 30px;
}

.showStaging {
	opacity: 1;
}

.headerLogo img {
	max-width: unset;
	height: 50px;
}

@media only screen and (min-width: 501px) and (max-width: 870px) {
	.headerLogo img {
		height: 40px;
		max-width: unset;
	}
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
	.headerLogo img {
		max-height: 30px;
		width: auto;
	}

	.staging {
		font-weight: bold;
		color: var(--errorColor);
		align-self: center;
		font-size: 14px;
		border: 3px solid var(--errorColor);
		padding: 1px 5px;
	}
}

@media only screen and (max-width: 850px) and (min-width: 721px) {
	.headerLogo img {
		max-height: 40px;
		max-width: unset;
	}

	.headerLogo {
		align-self: start;
		grid-column: 1/3;
		grid-row: 1/2;
	}
}

@media only screen and (max-width: 1300px) {
	.headerLogo {
		align-self: start;
		grid-column: 1/3;
		grid-row: 1/2;
	}
}

@media only screen and (max-width: 520px) {
	.profile {
		padding-left: calc(0.5 * var(--generalPaddings));
	}
}

@media only screen and (max-width: 500px) {
	li a {
		padding: calc(0.2 * var(--generalPaddings)) calc(0.3 * var(--generalPaddings));
	}
}

@media only screen and (max-width: 750px) {
	.styleOnLoginPage.menu {
		width: auto;
		justify-self: end;
	}

	.login-media {
		grid-column: 4/5;
		justify-self: end
	}
}
</style>
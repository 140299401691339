<template>
	<pop-up-window
		:show="showDialog"
		:showClose=false
		class="confirmation"
		:top10=true
	>
		<template #title>
			<h1 class="text-2xl ">{{ $t('popUp.release.title') }}</h1>
		</template>
		<template #content>
			<div class="flex flex-col items-center ">
				<div class="flex flex-col items-start">
				<div>
					<h1 v-if="attachmentAdded"  >
						<i class="icon-notification info-color pr-2"></i>
						{{$t('postDrop.releaseDocument') }}?
					</h1>
					<h1 v-else>
						<i class="icon-notification info-color pr-2"></i>{{
							$t('confirmation.description.confirmReleaseDocument')
						}}
					</h1>
				</div>

				<h1 class="text-base ml-8 flex flex-row  align-middle" v-if="warning !== null" >
					<span class="material-icons-outlined mr-1" style="font-size: 20px; color: #ffc107;">warning_amber</span>
					{{warning}}
				</h1>

				<div v-if="showEdi" class="flex flex-row ml-8 items-center">
					<input type="radio" value="changePoNumber" v-model="selectedOption" @click="deselelectOption" class="custom-radio mr-2 lg:ml-1"/>
					<p class="text-base">{{$t('documentDetail.releaseDocument.changePoNumber')}}</p>
				</div>
				<div v-if="selectedOption === 'changePoNumber' && showEdi" class="w-4/5 ml-16">
					<p class="text-start">{{ $t('documentDetail.resendDocument.edi.CustomerPOReference') }}</p>
					<input
						type='text'
						v-model="originalPoReference"
						class="form-control text-popup w-full"
						disabled
						style="margin-right: 10px; height: 37px"
					/>
					<p class="text-start">{{ $t('documentDetail.resendDocument.edi.poNumber') }}</p>
					<input
						type='text'
						v-model="poNumber"
						class="form-control text-popup w-full"
						style="margin-right: 10px; height: 37px"
					/>
				</div>
				<div class="flex flex-row ml-8 items-center">
					<input type="radio" value="dontSendDocument" v-model="selectedOption" @click="deselelectOption" class="custom-radio mr-2 lg:ml-1"/>
					<p class="text-base">{{$t('documentDetail.releaseDocument.doNotSend')}}</p>
				</div>
				</div>
			</div>

			<div class="btn-content mt-2 mb-10 grey-line">
				<div>
					<button class="btn custom-button shadow-md mr-2 text-popup" @click="approveRelease">
						{{ $t('buttons.approveRelease') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 text-popup" @click="cancelRelease">
						{{ $t('buttons.cancel') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>

import {defineComponent, onMounted, ref, watch} from "vue";
import PopUpWindow from "@/components/UI/PopUpWindow.vue";
import store from "@/store";

export default defineComponent({
	components: {PopUpWindow},
	props: ['showDialog', 'warning', 'docId', 'attachmentAdded'],
	emits: ['approveRelease', 'declineRelease', 'cancelRelease'],


	setup(props, {emit}) {
		const originalPoReference = ref('')
		const poNumber = ref('')
		const showEdi = ref(false)
		const changePoNumber = ref(false)
		const dontSendDocument = ref(false)
		const selectedOption = ref('')
		onMounted(() => {
			if (store.state.documentDetailData.channels?.edi?.network) {
				let provider = store.state.supplierGroup.data.channelsConfig.edi.ediChannelProviders[store.state.documentDetailData.channels?.edi?.network]?.provider
				showEdi.value = provider === "NYMUS_PEPPOL"
			}
			let customerPoReference = store.state.documentDetailData.billingOptionalData?.documentReferences?.find(ref => ref.description === "CustomerPOReference")
			poNumber.value = customerPoReference === undefined ? '' : customerPoReference.value
			originalPoReference.value = customerPoReference === undefined ? '' : customerPoReference.value
		})
		const approveRelease = () => {
			if (selectedOption.value === 'dontSendDocument') declineRelease()
			else if (poNumber.value === originalPoReference.value) emit('approveRelease')
			else emit('approveRelease', poNumber.value)
		}
		const declineRelease = () => {
		 	emit('declineRelease')
		}
		const cancelRelease = () => {
			emit('cancelRelease')
		}

		const deselelectOption = (event) => {
			if (selectedOption.value === event.target.value) selectedOption.value = ''
		}

		return {
			showEdi,
			changePoNumber,
			poNumber,
			originalPoReference,
			dontSendDocument,
			approveRelease,
			declineRelease,
			cancelRelease,
			deselelectOption,
			selectedOption
		}
	}
})
</script>